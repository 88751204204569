/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const SubscriptionListFilterPopup = ({
  setfilterStartDueDate,
  setfilterEndDueDate,
  setfilterStartDueTime,
  setfilterEndDueTime,
  
  setFilterSubscriberName,
  setFilterSubscriberEmail,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  

  const [startDueDate, setStartDueDate] = useState("");
  const [startDueTime, setStartDueTime] = useState("00:01");

  const [endDueDate, setEndDueDate] = useState("");
  const [endDueTime, setEndDueTime] = useState("23:59");

  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [errorMessage, seterrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    dateCompareWarning: false,
  });


  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (startDueDate !== "" && endDueDate === "") {
        setValidation((prevState) => ({ ...prevState, dateCompareWarning: true }));
        isValid = false;
    }else if (startDueDate === "" && endDueDate !== "") {    
        setValidation((prevState) => ({ ...prevState, dateCompareWarning: true }));
        isValid = false;
    }else if (startDueDate !== "" && endDueDate !== "") {
      if (startDueDate > endDueDate) {
        setValidation((prevState) => ({ ...prevState, dateCompareWarning: true }));
        isValid = false;
      }
    }

    return isValid;
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {

    if (validate()) {


      seterrorMessage("");

      if (startDueDate !== "" && endDueDate !== "") {
        setfilterStartDueDate(startDueDate);
        setfilterEndDueDate(endDueDate);
      }

      if (startDueTime !== "" && endDueTime !== "") {
        setfilterStartDueTime(startDueTime);
        setfilterEndDueTime(endDueTime);
      }

      setFilterSubscriberName(subscriberName);
      setFilterSubscriberEmail(subscriberEmail);

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasSubscriptionListFilter");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
      resetHandler();

    } else {
      seterrorMessage("Please fill the required fields");
    }

  };

  //function for reset search
  const resetHandler = () => {
    setStartDueDate("");
    setEndDueDate("");
    setStartDueTime("");
    setEndDueTime("");
    
    setSubscriberName("");
    setSubscriberEmail("");

  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSubscriptionListFilter"
      aria-labelledby="offcanvasCompanyListFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date range */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Due Dates</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Due date from"
                value={startDueDate}
                onChange={(e) => {
                  setStartDueDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateCompareWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              
            
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Due date to"
                value={endDueDate}
                onChange={(e) => {
                  setEndDueDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateCompareWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              
            </div>

            {validation.dateCompareWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>End date should be greater than start date!</span>
                </p>
              </div>
            )}
          </div>


          {/* buyer name */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Subscriber Name</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={subscriberName}
              onChange={(e) => setSubscriberName(e.target.value)}
            />
          </div>

          {/* buyer email */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>Subscriber Email</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Email"
              value={subscriberEmail}
              onChange={(e) => setSubscriberEmail(e.target.value)}
            />
          </div>

    
          {/* ------ search button start ----- */}
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            Search
          </button>
          {/* ------ search button end ----- */}
        </form>
      </div>
    </div>
  );
};

export default SubscriptionListFilterPopup;
