/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//** import components
import DashboardHeader from "../Header/DashboardHeader";
import TicketDashboardFilterPopup from "components/DashboardComponents/Popup/TicketDashboardFilterPopup";


import DashboardActivityTab from "../Tab/DashboardActivityTab";

import AverageSalesTab from "../Tab/AverageSales";
import TicketsPerYear from "../Tab/TicketsPerYear";
import SalesByChannel from "../Tab/SalesByChannel";
import TicketsPerMonth from "../Tab/TicketsPerMonth";
import TicketsPerDaysOfWeek from "../Tab/TicketsByDayOfWeek";

import TicketsByGender from "../Tab/TicketsByGender";
import TicketsByPrice from "../Tab/TicketsByPrice";
import TicketsByPromotion from "../Tab/TicketsByPromotion";
import TicketsBySector from "../Tab/TicketsBySector";
import TicketsByBill from "../Tab/TicketsByBill";
import TicketsByWork from "../Tab/TicketsPerWork";
import DashboardCustomerTab from "../Tab/DashboardCustomerTab";

import BestBuyerTab from "../Tab/BestBuyerRanking";
import BuyerDistinctEventCount from "../Tab/BuyerDistinctEventCount";

const TicketDashboardBody = () => {

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [dashboardTab, setDashboardTab] = useState("activity");

  const [organizations, setOrganizations] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [operationStartDate, setOperationStartDate] = useState("");
  const [operationEndDate, setOperationEndDate] = useState("");

  const [enclosures, setEnclosures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [events, setEvents] = useState([]);

  const [channels, setChannels] = useState([]);
  const [zones, setZones] = useState([]);

  const [zipcodes, setZipcodes] = useState([]);
  
  const [refreshData, setRefreshData] = useState(false);

  const [applyFilter, setApplyFilter] = useState(false);

  const commonProps = {
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,
  zipcodes,
  setApplyFilter,
  applyFilter,
  setRefreshData,

  refreshData
};

useEffect(()=>{
  console.log('refreshData >>>>>', refreshData)
  
  if(refreshData){
     setApplyFilter(false);
  }
 
},[refreshData]);

  return (
    <>
    <div id="content_wrapper">
      <section className="dashboard-wrapper">
        {/* <!-- ========== Start dashboard header component ========== --> */}
        <DashboardHeader 
          setRefreshData={setRefreshData}
          setDashboardTab={setDashboardTab}
        />
        {/* <!-- ========== End dashboard header component ========== --> */}

        {/* <!-- ========== Start dashboard tab components ========== --> */}
        <div className="tab-container">
          <div className="container-fluid px-lg-5">
            <div className="tab-content" id="myTabContent">
              {/* <!-- ========== Start dashboard activity tab Section ========== --> */}
             
                {dashboardTab === 'activity' && (

                  <DashboardActivityTab 
                      {...commonProps}
                  />

                )}

                {/* customer */}
       
                  {dashboardTab === 'customer' && (
                    <DashboardCustomerTab 
                      {...commonProps}
                    />
                  )}
                
                {/* sales per year per month */}
       
                  {dashboardTab === 'sales' && (
                    <AverageSalesTab 
                      {...commonProps}
                    />
                  )}
                 

                  {/* sales per year per month */}
              
                    {dashboardTab === 'ticketsperyear' && (
                      <TicketsPerYear 
                        {...commonProps}
                      />
                    )}
                

                  {/* sales per channel */}
                  
                    {dashboardTab === 'salesbychannel' && (
                      <SalesByChannel 
                        {...commonProps}
                      />
                    )}
                  

                  {/* sales per month per year */}
                  
                    {dashboardTab === 'ticketspermonth' && (
                      <TicketsPerMonth
                        {...commonProps}
                      />
                    )}
                  

                  {/* sales per day by week */}
                  
                    {dashboardTab === 'ticketsperdayofweek' && (
                      <TicketsPerDaysOfWeek 
                        {...commonProps}
                      />
                    )}
               

                  {/* tickets by gender */}
                 
                    {dashboardTab === 'ticketsbygender' && (
                      <TicketsByGender 
                        {...commonProps}
                      />
                    )}
                  

                  {/* tickets by price */}
                  
                    {dashboardTab === 'ticketsbyprice' && (
                      <TicketsByPrice 
                        {...commonProps}
                      />
                    )}
                  

                  {/* tickets by promotion */}
                  
                    {dashboardTab === 'ticketsbypromotion' && (
                      <TicketsByPromotion 
                        {...commonProps}
                      />
                    )}
                  

                  {/* tickets by sector */}
                  
                    {dashboardTab === 'ticketsbysector' && (
                      <TicketsBySector 
                        {...commonProps}
                      />
                    )}
                  

                  {/* tickets by bill */}
                  
                    {dashboardTab === 'ticketsbybill' && (
                      <TicketsByBill 
                        {...commonProps}
                      />
                    )}

                  

                  {/* tickets by work */}
                  
                    {dashboardTab === 'ticketsbywork' && (
                      <TicketsByWork 
                        {...commonProps}
                      />
                    )}
                  

                  {/* Best Buyer */}
                  
                    {dashboardTab === 'bestbuyer' && (
                      <BestBuyerTab 
                        {...commonProps}
                      />
                    )}


                  {/* distinct event count by customer */}
                  
                    {dashboardTab === 'buyerdistinctevent' && (
                      <BuyerDistinctEventCount 
                        {...commonProps}
                      />
                    )}

                  
               
            </div>
          </div>
        </div>
        {/* <!-- ========== End dashboard tab components ========== --> */}
      </section>
    </div>

    {/* Filter popup for dashboard */}
      <TicketDashboardFilterPopup 
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setOperationStartDate={setOperationStartDate}
        setOperationEndDate={setOperationEndDate}
        setOrganizations={setOrganizations}
        setEnclosures={setEnclosures}
        setSectors={setSectors}
        setEventTypes={setEventTypes}
        setEvents={setEvents}
        setZones={setZones}
        setZipcodes={setZipcodes}
        setChannels={setChannels}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
      />

    </>
  );
};

export default TicketDashboardBody;
