/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";
import TicketsListHeader from "../TicketsListHeader/TicketsListHeader";
import { Link } from "react-router-dom";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import TicketsListFilterPopup from "../Popup/TicketsListFilterPopup";
import CSVUploadModal from "../Modals/CSVUploadModal";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import ImportModal from "../Modals/ImportModal";
//import AddToListModal from "components/Common/Modal/AddToListModal";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";

const TicketListBody = () => {

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTicketsIds, setSelectedTicketsIds] = useState([]);
  const [selectedTicketsEmails, setSelectedTicketsEmails] = useState([]);


  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //filter options
  // filter params states to be sent to API and as props to child components ------------
    // filter params states to be sent to API and as props to child components ------------
  const [applyFilter, setApplyFilter] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [reloadDefault, setReloadDefault] = useState(false); // reload the default view used on reset click


  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] =  useState("00:01");
  const [endTime, setEndTime] = useState("23:59");

  const [operationStartDate, setOperationStartDate] = useState("");
  const [operationEndDate, setOperationEndDate] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [events, setEvents] = useState([]);
  const [enclosures, setEnclosures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [channels, setChannels] = useState([]);
  const [zones, setZones] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);

  const [entrycode, setEntrycode] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerLocation, setCustomerLocation] = useState("");


  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedTicketsIds([]);
    setSelectedTicketsEmails("");
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tickets
  const getTicketsList = async (isFilter = false) => {
    setRowSelection({});
    setSelectedTicketsIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MY_TICKETS +
        `?token=${token}&userrolestring=${userInfo.role.slug}`;

      if (startDate !== "" && endDate !== "") {
        requestUrl += `&filterstartdate=${startDate}&filterenddate=${endDate}`;

        if (startTime !== "" && endTime !== "") {
          requestUrl += `&filterstarttime=${startTime}&filterendtime=${endTime}`;
        }
      }


      if (operationStartDate !== "" && operationEndDate !== "") {
        requestUrl += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
      }

      if (organizations && organizations.length > 0) {
        organizations.forEach((data) => {
          requestUrl += `&filterorganizations[]=${encodeURIComponent(data)}`;
        });
      }

      if (enclosures && enclosures.length > 0) {
        enclosures.forEach((data) => {
          requestUrl += `&filterenclosures[]=${encodeURIComponent(data)}`;
        });
      }

      if (sectors && sectors.length > 0) {
        sectors.forEach((data) => {
          requestUrl += `&filtersectors[]=${encodeURIComponent(data)}`;
        });
      }

      // if (eventTypes && eventTypes.length > 0) {
      //   eventTypes.forEach((data) => {
      //     requestUrl += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      //   });
      // }

      if (events && events.length > 0) {
        events.forEach((data) => {
          requestUrl += `&filterevent[]=${encodeURIComponent(data)}`;
        });
      }

      if (channels && channels.length > 0) {
        channels.forEach((data) => {
          requestUrl += `&filterchannel[]=${encodeURIComponent(data)}`;
        });
      }

      if (zones && zones.length > 0) {
        zones.forEach((data) => {
          requestUrl += `&filterzone[]=${encodeURIComponent(data)}`;
        });
      }

      if (zipcodes && zipcodes.length > 0) {
        zipcodes.forEach((data) => {
          requestUrl += `&filterzipcode[]=${encodeURIComponent(data)}`;
        });
      }

      if(entrycode !== ""){
        requestUrl += `&filterentrycode=${entrycode}`;
      }

      if(customerName !== ""){
        requestUrl += `&filtercustomername=${customerName}`;
      }

      if(customerEmail !== ""){
        requestUrl += `&filtercustomeremail=${customerEmail}`;
      }

      requestUrl += `&isfilter=${isFilter}`;

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setTicketsList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    // for (let selectedId of selectedCompanyIds) {
    //   try {
    //     let statusData = {
    //       status: "0",
    //     };
    //     let requestUrl =
    //       url.API_BASE_URL +
    //       url.API_UPDATE_COMPANY +
    //       `/${selectedId}` +
    //       `?token=${token}`;
    //     const response = await putData(requestUrl, statusData);
    //     if (response.status) {
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // }
    // getAllCompanyList();
  };


  //function for reset filter
  const resetFilterData = () => {
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setOperationStartDate("");
    setOperationEndDate("");
    setOrganizations([]);
    setZipcodes([]);
    setSectors([]);
    setChannels([]);
    setZones([]);
    setEntrycode("");
    setCustomerName("");
    setCustomerEmail("");

    setApplyFilter(false);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(() => [
    { accessorKey: "source", header: "Source" },
    { accessorKey: "session_date", header: "Fecha sesión" },
    { accessorKey: "session_date_formated", header: "Día sesión" },
    { accessorKey: "session_time_formated", header: "Hora sesión" },
    { accessorKey: "company_name", header: "Organizador" },
    { accessorKey: "producer", header: "Productor" },
    { accessorKey: "group", header: "Agrupación" },
    { accessorKey: "event", header: "Evento" },
    {
      accessorKey: "event_reference",
      header: "Referencia de evento",
      size: 300,
    },
    { accessorKey: "encloser", header: "Recinto" },
    { accessorKey: "session", header: "Sesión" },
    { accessorKey: "sector", header: "Sector" },

    { accessorKey: "access", header: "Acceso" },
    { accessorKey: "seat_row", header: "Fila" },
    { accessorKey: "seat", header: "Asiento" },
    { accessorKey: "zone", header: "Zona no num." },
    { accessorKey: "tier", header: "Tier" },
    { accessorKey: "tier_section", header: "Nombre Tramo (Tier)" },
    { accessorKey: "price_zone", header: "Zona de precio" },
    { accessorKey: "onebox_category", header: "Categoría Onebox" },
    { accessorKey: "own_category", header: "Categoría propia" },
    { accessorKey: "impressions_count", header: "Nº Impresiones" },

    { accessorKey: "access_count", header: "Nº Accesos" },
    { accessorKey: "entrycode", header: "Cod. Entrada" },
    { accessorKey: "fee", header: "Tarifa" },
    { accessorKey: "attendent_type", header: "Individual/Grupos" },
    { accessorKey: "external_entry_code", header: "Cod.Entrada externo" },
    { accessorKey: "external_access", header: "Acceso externo" },
    { accessorKey: "external_reference", header: "Referencia externa" },
    { accessorKey: "external_door", header: "Puerta externa" },
    { accessorKey: "external_zone", header: "Zona externa" },
    { accessorKey: "entry_type", header: "Tipo de entrada" },

    { accessorKey: "name", header: "Nombre" },
    { accessorKey: "surname", header: "Apellidos" },
    { accessorKey: "phone_client", header: "Teléfono cliente" },
    { accessorKey: "email_client", header: "Email cliente" },
    { accessorKey: "address_client", header: "Dirección cliente" },
    { accessorKey: "country_client", header: "País cliente" },
    { accessorKey: "gender_client", header: "Género cliente" },
    { accessorKey: "language_client", header: "Idioma cliente" },
    { accessorKey: "purchasemodule", header: "Module" },
    { accessorKey: "purchasecomponent", header: "Component" },
    {
      accessorKey: "dob_client",
      header: "Fecha de nacimiento cliente",
      size: 300,
    },
    {
      accessorKey: "authorize_communication",
      header: "Autoriza comunicaciones",
      size: 300,
    },

    { accessorKey: "group_name", header: "Nombre Grupo" },
    { accessorKey: "group_attendees", header: "Asistentes grupo" },
    { accessorKey: "group_companions", header: "Acompañantes grupo" },
    { accessorKey: "province", header: "Provincia" },
    { accessorKey: "zipcode", header: "Codigo postal" },
    { accessorKey: "population", header: "Población" },
    { accessorKey: "doc_id", header: "Doc.id" },
    {
      accessorKey: "additional_info",
      header: "Información adicional",
      size: 300,
    },
    {
      accessorKey: "professional_client_name",
      header: "Nombre Cliente Profesional",
      size: 300,
    },
    { accessorKey: "channel_entity", header: "Entidad canal" },

    { accessorKey: "channel", header: "Canal" },
    { accessorKey: "operation_type", header: "Tipo operación" },
    { accessorKey: "operation_code", header: "Código de operación" },
    { accessorKey: "operation_date", header: "Fecha operación" },
    { accessorKey: "operation_time", header: "Hora op." },
    { accessorKey: "parent_operation_type", header: "Tipo ope. Padre" },
    { accessorKey: "parent_operation_code", header: "Cod. Op. Padre" },
    { accessorKey: "method_delivery", header: "Método de entrega" },
    { accessorKey: "sure", header: "Seguro" },
    { accessorKey: "insurance_carrier", header: "Aseguradora" },

    { accessorKey: "policy", header: "Póliza" },
    {
      accessorKey: "professional_client_category",
      header: "Categoría cliente profesional",
      size: 300,
    },
    { accessorKey: "client_type", header: "Tipo de cliente" },
    { accessorKey: "base_price", header: "Precio base" },
    { accessorKey: "automatic_promotion", header: "Promoción Automatica" },
    { accessorKey: "pa_amount", header: "Importe PA" },
    { accessorKey: "discount_client", header: "Descuento cliente" },
    { accessorKey: "dc_activator", header: "Activador DC" },
    { accessorKey: "dc_amount", header: "Importe DC" },
    { accessorKey: "dc_collective", header: "Colectivo DC" },

    { accessorKey: "client_promotion", header: "Promoción cliente" },
    { accessorKey: "pc_activator", header: "Activador PC" },
    { accessorKey: "pc_amount", header: "Importe PC" },
    { accessorKey: "pc_collective", header: "Colectivo PC" },
    {
      accessorKey: "auto_promotion_operation",
      header: "Promoción automática de operación",
      size: 350,
    },
    {
      accessorKey: "name_auto_promotion_operation",
      header: "Nombre promoción automática de operación",
      size: 350,
    },
    {
      accessorKey: "manual_promotion_operation",
      header: "Promoción manual de operación",
      size: 350,
    },
    {
      accessorKey: "name_manual_promotion_operation",
      header: "Nombre promoción manual de operación",
      size: 350,
    },
    {
      accessorKey: "collective_manual_promotion_operation",
      header: "Colectivo promoción manual de operación ",
      size: 350,
    },
    {
      accessorKey: "code_manual_promotion_operation",
      header: "Código promoción manual de operación",
      size: 350,
    },

    { accessorKey: "entrance_price", header: "Precio entrada" },
    { accessorKey: "organizer_surcharge", header: "Recargos organizador" },
    { accessorKey: "channel_surcharge", header: "Recargos canal" },
    { accessorKey: "tax", header: "Impuesto" },
    { accessorKey: "purchase_reference", header: "Referencia de compra" },
    { accessorKey: "pos_code", header: "Código TPV" },
    { accessorKey: "delivery_cost", header: "Gastos de entrega" },
    { accessorKey: "safe_amount", header: "Importe seguro" },
    { accessorKey: "way_to_pay", header: "Forma de pago" },
    { accessorKey: "payment_gateway", header: "Pasarela de pago" },

    { accessorKey: "final_price", header: "Precio final" },
    { accessorKey: "channel_cost", header: "Costes del canal" },
    { accessorKey: "currency", header: "Moneda" },
    {
        accessorKey: 'tags',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tags && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tags.map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
  ]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {

      const selectedTicketIdArr = selectedIdsArray.map((id) => {
        const [ticketId] = id.split("/");
        return ticketId;
      });

      let previouslyAddedEmails = []; // array to store previously added emails

      const selectTicketEmails = selectedIdsArray.filter((id) => {
        const [, ticketMail] = id.split("/");
        if (!previouslyAddedEmails.includes(ticketMail)) {
          previouslyAddedEmails.push(ticketMail);
          return true;
        }
        return false;
      }).map((id) => {
        const [, ticketMail] = id.split("/");
        return ticketMail;
      });

      const selectedEmailString = selectTicketEmails.join(",");

      setSelectedTicketsIds(selectedTicketIdArr);
      setSelectedTicketsEmails(selectedEmailString)

    } else {
      setSelectedTicketsIds([]);
      setSelectedTicketsEmails("")
    }
  }, [rowSelection]);


  // default call when component loads
  useEffect(() => {
    getTicketsList(false);
    getAllTags();
  }, []);

  // when reload is triggred, reset Filter data
  useEffect(()=>{    

    if(refreshData){
      resetFilterData();

      setReloadDefault(true);
      setRefreshData(false);
    }  

  },[refreshData]);


  // reload default data, when reload is triggred
  useEffect(()=>{

    if(reloadDefault){
      getTicketsList(false);
      setReloadDefault(false);
    }
  
  },[reloadDefault]);


  // when filter is triggred
  useEffect(() => {
    if (
      applyFilter &&
      (
        (startDate !== "" && endDate !== "") ||
        (operationStartDate !== "" && operationEndDate !== "") ||
        events.length>0 ||
        organizations.length > 0 ||
        enclosures.length > 0 ||
        sectors.length > 0 ||
        channels.length > 0 ||
        zones.length > 0 ||
        zipcodes.length > 0 ||
        entrycode != "" ||
        customerName != "" ||
        customerEmail != "" 
      )) 
      {
      
        getTicketsList(true);

        // set applyfilter false to reset
        if (applyFilter) {
          setApplyFilter(false);
        }

      }
  }, [applyFilter]);


  

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_TICKET")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <TicketsListHeader
              setRefreshData={setRefreshData}
              changeStatusHandler={changeStatusHandler}
            />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={ticketsList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) =>
                    `${row._id}/${row.email_client}`
                  } // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableColumnOrdering={true}
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedTicketsIds}
          moduleName="ticket"
          afterTagModalClose={getTicketsList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ------ new mail popup render here ----- */}
        <ConversationNewMailPopup
          contactPersonEmail={selectedTicketsEmails}
          reloadList={afterEmailClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType} 
        />

        {/* ------- filter popup ------- */}
        <TicketsListFilterPopup
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStartTime={setStartTime}
          setEndTime={setEndTime}

          setOperationStartDate={setOperationStartDate}
          setOperationEndDate={setOperationEndDate}

          setOrganizations={setOrganizations}
          setEnclosures={setEnclosures}
          setSectors={setSectors}
          setEvents={setEvents}
          setChannels={setChannels}
          setZones={setZones}
          setZipcodes={setZipcodes}
          setEntrycode={setEntrycode}
          
          setCustomerName={setCustomerName}
          setCustomerEmail={setCustomerEmail}
          setCustomerLocation={setCustomerLocation}
          
          setApplyFilter={setApplyFilter}
          refreshData={refreshData}
        />

        {/* CVS upload modal */}
        <CSVUploadModal />

        <ImportModal
          afterModalClose={() => {
            console.log("Success");
          }}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You don't have privilege to see this content
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default TicketListBody;
