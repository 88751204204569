import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DashboardHeader = ({ setRefreshData, setDashboardTab }) => {
  const { t } = useTranslation(); //for translation

  const ecosystemSlug = localStorage.getItem("ecosystemslug");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [selectedDashboardTab, setSelectedDashboardTab] = useState('activity');

  console.log("ecosystemSlug", ecosystemSlug);
  console.log("userInfo role slug", userInfo.role.slug);

  return (
    <div className="tab-links-container bg-white py-4 shadow-sm">
      <div className="container-fluid px-lg-5">
        {/* <!-- ========== Start header title Section ========== --> */}
        <h1 className="h2 mb-3">{t("Dashboard")} - {t("Tickets")}</h1>
        {/* <!-- ========== End header title Section ========== --> */}

        {/* <!-- ========== Start list of tab links section ========== --> */}
        <div className="d-flex align-items-end align-items-md-center justify-content-between flex-column flex-md-row gap-3">
          
          <div className="">
            
            <div className="row col-12">
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'activity' ? "btn-primary" :"btn-outline-primary"}`}
                      id="activity-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('activity')
                        setDashboardTab('activity')
                      }}
                    >
                      {t("Activity")}
                    </Link>

                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'customer' ? "btn-primary" :"btn-outline-primary"}`}
                      id="activity-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('customer')
                        setDashboardTab('customer')
                      }}
                    >
                      {t("Customers")}
                    </Link>

                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'sales' ? "btn-primary" :"btn-outline-primary"}`}
                      id="avg-sales-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('sales')
                        setDashboardTab('sales')
                      }}
                    >
                      Average Sales
                    </Link>
          

                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsperyear' ? "btn-primary" :"btn-outline-primary"}`}
                      id="tickets-year-tab"
                      aria-selected="false"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsperyear')
                        setDashboardTab('ticketsperyear')
                      }}
                    >
                      Tickets sold (year / month)
                    </Link>
              

      
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'salesbychannel' ? "btn-primary" :"btn-outline-primary"}`}
                      id="records-channel-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('salesbychannel')
                        setDashboardTab('salesbychannel')
                      }}
                    >
                      Sales by Channel
                    </Link>
                

          
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketspermonth' ? "btn-primary" :"btn-outline-primary"}`}
                      id="tickets-month-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketspermonth')
                        setDashboardTab('ticketspermonth')
                      }}
                    >
                      Tickets sold (month / year)
                    </Link>
              
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsperdayofweek' ? "btn-primary" :"btn-outline-primary"}`}
                      id="tickets-days-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsperdayofweek')
                        setDashboardTab('ticketsperdayofweek')
                      }}
                    >
                      Tickets sold (days of week)
                    </Link>
                
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsbygender' ? "btn-primary" :"btn-outline-primary"}`}
                      id="ticket-gender-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsbygender')
                        setDashboardTab('ticketsbygender')
                      }}
                    >
                      By Gender
                    </Link>
                  
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsbyprice' ? "btn-primary" :"btn-outline-primary"}`}
                      id="ticket-price-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsbyprice')
                        setDashboardTab('ticketsbyprice')
                      }}
                    >
                      By Price
                    </Link>
                
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsbypromotion' ? "btn-primary" :"btn-outline-primary"}`}
                      id="ticket-promotion-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsbypromotion')
                        setDashboardTab('ticketsbypromotion')
                      }}
                    >
                      By Promotion
                    </Link>
                  
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsbysector' ? "btn-primary" :"btn-outline-primary"}`}
                      id="ticket-sector-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsbysector')
                        setDashboardTab('ticketsbysector')
                      }}
                    >
                      By Sector
                    </Link>
                  
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsbybill' ? "btn-primary" :"btn-outline-primary"}`}
                      id="ticket-bill-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsbybill')
                        setDashboardTab('ticketsbybill')
                      }}
                    >
                      By Bill
                    </Link>
                  
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'ticketsbywork' ? "btn-primary" :"btn-outline-primary"}`}
                      id="ticket-work-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('ticketsbywork')
                        setDashboardTab('ticketsbywork')
                      }}
                    >
                      By Work
                    </Link>
                  
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'bestbuyer' ? "btn-primary" :"btn-outline-primary"}`}
                      id="best-buyer-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('bestbuyer')
                        setDashboardTab('bestbuyer')
                      }}
                    >
                      Best Buyer
                    </Link>

                    
                    <Link
                      to="#"
                      className={`btn btn-sm mb-1 me-1 col-lg-2 ${selectedDashboardTab === 'buyerdistinctevent' ? "btn-primary" :"btn-outline-primary"}`}
                      id="buyerdistinctevent-tab"
                      onClick={()=>{
                        setSelectedDashboardTab('buyerdistinctevent')
                        setDashboardTab('buyerdistinctevent')
                      }}
                    >
                      Buyer with Distinct Events
                    </Link>
              
            </div>
          </div>

          <div className="d-flex">
            <Link
              to="#"
              onClick={() => {
                setRefreshData(true);
              }}
              className="col-5 me-1 btn body-bg align-items-center gap-1 px-3 py-2 fs-sm fw-semibold text-black rounded-10"
              type="button"
            >
              <span className="d-block material-symbols-outlined icon-md">
                refresh
              </span>
            </Link>


            <Link
              to="#"
              className="col-auto btn body-bg align-items-center gap-1 px-3 py-2 fs-sm fw-semibold text-black rounded-10"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDashboardFilter"
              aria-controls="offcanvasFilter"
            >
              <span className="d-block material-symbols-outlined icon-md">
                tune
              </span>
              <span className="d-block">{t("Filter")}</span>
            </Link>
          </div>

        </div>
        {/* <!-- ========== End list of tab links section ========== --> */}
      </div>
    </div>
  );
};

export default DashboardHeader;
