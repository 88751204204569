import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TicketsListHeader = ({ setRefreshData, changeStatusHandler }) => {

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  
  const location = useLocation();

  let brTitle = 'Tickets';

  if (location.pathname === '/admin/ticketscustomer/list') {
    brTitle = 'Ticket Customers';
  } 

  const breadcrumbTxt = [{ title: brTitle }];
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ------- search section -------- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbTxt} />
        </div>

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={() => {
                setRefreshData(true);
            }}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          {/* ------- ONEBOX action section ---------- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              View
            </button>

            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              {/* -------- sync onebox data -------- */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="/admin/tickets/list"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list_alt
                  </span>
                  <span className="d-block">{t("Tickets View")}</span>
                </Link>
              </li>

              {/* -------- upload by csv ----------- */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="/admin/ticketscustomer/list"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    people
                  </span>
                  <span className="d-block">{t("Customer View")}</span>
                </Link>
              </li>
              
            </ul>
          </div>

          

          {/* ------- ONEBOX action section ---------- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              OneBox
            </button>

            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              {/* -------- sync onebox data -------- */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#importModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    sync
                  </span>
                  <span className="d-block">{t("Sync by date")}</span>
                </Link>
              </li>

              {/* -------- upload by csv ----------- */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#csvUploadModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    upload
                  </span>
                  <span className="d-block">{t("CSV Upload")}</span>
                </Link>
              </li>
              
            </ul>
          </div>



          {/* ------- action section ---------- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>

            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              {/* -------- add label -------- */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>

              {/* -------- delete ----------- */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNewMail"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    email
                  </span>
                  <span className="d-block">{t("Send Mail")}</span>
                </Link>
              </li>
              
            </ul>
          </div>

          {/* --------- filter ---------- */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTicketListFilter"
            aria-controls="offcanvasTicketListFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">Filter</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TicketsListHeader;
