/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { useTranslation } from "react-i18next";

const SavePlanPopup = ({ afterPopupCLose, planId, setPlanId }) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [pricing, setPricing] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [duration, setDuration] = useState(30);
  const [noOfPersonsAllowed, setnoOfPersonsAllowed] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  // KPIs form repeater ----------------------------------------

  // create a template to append when click Add Item
  const kpistemplate = { value: "" };

  // state holding full form repeater data. append template by default
  const [kpis, setKpis] = planId ? useState([kpistemplate]) : useState([]);

  //append more template on click add
  const onAddKpi = () => {
    setKpis([...kpis, kpistemplate]);
  };

  //update the data in selected block when changes done
  const changeKpi = (index, data) => {
    const updatedFormValues = kpis.map((kpi, i) =>
      index == i ? Object.assign(kpi, { value: data }) : kpi
    );
    setKpis(updatedFormValues);
  };

  //delete selected block
  const onDeleteKpi = (index) => {
    if (index !== 0) {
      const filteredBlock = [...kpis];
      filteredBlock.splice(index, 1);
      setKpis(filteredBlock);
    }
  };

  // form repeater ends here ---------------------------------

  /* hook for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    pricingWarning: false,
    durationWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (pricing === "") {
      setValidation((prevState) => ({ ...prevState, pricingWarning: true }));
      isValid = false;
    }

    if (duration === "") {
      setValidation((prevState) => ({ ...prevState, durationWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //get details
  const getPlanDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PLAN_DETAILS +
        `/${planId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      //console.log('details response', response);

      if (response.status) {
        setTitle(response.data.title);
        setDescription(response.data.description);
        setPricing(response.data.price);
        setDuration(response.data.duration);
        setnoOfPersonsAllowed(response.data.persons);

        if (response.data.kpis.length > 0) {
          let kpiData = [];

          response.data.kpis.map((kpi) => {
            kpiData.push({ value: kpi });
          });

          setKpis(kpiData);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveHandler = async () => {
    if (validate()) {
      setIsSaving(true);

      try {
        let sendingData = {
          title,
          description,
          duration,
          price: pricing,
          persons: noOfPersonsAllowed || 0,
          discount: parseFloat(discount),
          kpis,
        };

        console.log("sendingData", sendingData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (planId) {
          // update plan -----------------
          requestUrl =
            requestUrl + url.API_UPDATE_PLAN + `/${planId}` + `?token=${token}`;

          response = await putData(requestUrl, sendingData);
        } else {
          // add new plan -----------
          requestUrl = requestUrl + url.API_ADD_PLAN + `?token=${token}`;

          response = await postData(requestUrl, sendingData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#savePlan")
          ).hide();
          resetHandler();
          afterPopupCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setTitle("");
    setDescription("");
    setPricing("");
    setDiscount("");
    setDuration("");
    setnoOfPersonsAllowed(0);
    setKpis([]);
    setPlanId(null);
  };

  useEffect(() => {
    if (planId) {
      getPlanDetails();
    }
  }, [planId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="savePlan"
      aria-labelledby="savePlan"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Plan")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Title")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                }}
              />
              {/* warning */}
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter a suitable title")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* description */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Description")}
              </label>
              <textarea
                name="planDescription"
                id="planDescription"
                cols="30"
                rows="4"
                className="form-control"
                placeholder={t("Enter Description")}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="form-group mb-3">
              <div className="row">
                {/* price */}
                <div className="col-lg-3 mb-lg-0">
                  <label
                    htmlFor="labelName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Price")}
                  </label>
                  <input
                    type="number"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter here")}
                    value={pricing}
                    onChange={(e) => {
                      setPricing(e.target.value);
                    }}
                  />
                  {/* warning */}
                  {validation.pricingWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter a price")}!</span>
                      </p>
                    </div>
                  )}
                </div>

                {/* duration */}
                <div className="col-lg-3 mb-lg-0">
                  <label
                    htmlFor="labelName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t(
                      "Duration days - 30/60/90/365"
                    )}
                  </label>
                  <input
                    type="number"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter here")}
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                  />
                  {/* warning */}
                  {validation.durationWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter valid duration in days")}!</span>
                      </p>
                    </div>
                  )}
                </div>

                {/* discount */}
                <div className="col-lg-3 mb-lg-0">
                  <label
                    htmlFor="labelName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Discount")} {' '} %
                  </label>
                  <input
                    type="number"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Discount")}
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                  />
                </div>

                {/* person */}
                <div className="col-lg-3 mb-lg-0">
                  <label
                    htmlFor="labelName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("No of persons allowed")}
                  </label>
                  <input
                    type="number"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("No of Person")}
                    value={noOfPersonsAllowed}
                    onChange={(e) => {
                      setnoOfPersonsAllowed(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Kpis / Features")}
              </label>
              {kpis.map((kpi, index) => {
                return (
                  <div className="row question mt-1 mb-2" key={index}>
                    {/* -------- question section start -------- */}
                    <div className="col-10 form-group mb-4">
                      {/* <label htmlFor="question"> KPIs</label> */}

                      <input
                        type="text"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Add Kpi to Plan")}
                        value={kpi.value}
                        onChange={(e) => {
                          changeKpi(index, e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Link
                        to="#"
                        onClick={() => {
                          onDeleteKpi(index);
                        }}
                        className=""
                      >
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="add-question my-3">
              <Link
                to="#"
                onClick={onAddKpi}
                id="add_question"
                className="text-primary fw-medium d-flex align-items-center gap-1"
              >
                <span className="material-symbols-outlined">add</span>
                <span>{t("Add More")}</span>
              </Link>
            </div>
          </div>

          <div className="action d-flex justify-content-between gap-3 mb-3">
            <button
              onClick={saveHandler}
              type="button"
              className="btn btn-primary"
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SavePlanPopup;
