/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams, useLocation  } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";
import { ecosystemSlug, invoiceOwnerEmail, invoiceBillingAddress } from "Config/Config";
import { useTranslation } from "react-i18next";
//import current date
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";


const SubscriptionCancel = () => {

    const { t } = useTranslation(); //for translation
    const params = useParams();
    const location = useLocation();
    //const planParam = new URLSearchParams(location.search).get('plan');
    const subscriptionId = params.id;

    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    // invoice states
    const [subscriptionDetails, setSubscriptionDetails] = useState({}); // object from subscription details
    const [customerEmail, setCustomerEmail] = useState('');

    const [newCustomerEmail, setNewCustomerEmail] = useState('');
    const [subscriberValidate, setSubscriberValidate] = useState(false);

    const [isCancelled, setIsCancelled] = useState(false);

    const [loading, setLoading] = useState(false);


    // get subscription details
    const getDetailsOfSubscription = async () => {
        try {
            let requestURL =
                url.API_BASE_URL + url.API_GET_SUBSCRIPTION_DETAILS + `/${subscriptionId}`;
                
            const response = await getData(requestURL);

            console.log('subscription Details', response);

            if (response.status) {

                setSubscriptionDetails(response.data); 

                setCustomerEmail(response.data?.customer?.email??'') 
                
                if(response.data.iscancelled){
                    setIsCancelled(response.data.iscancelled);
                }
            }
        } catch (error) {
            console.log(error)
        }
    };


    // get subscription details
    const cancelSubscription = async () => {
        try {

            let requestURL =
                url.API_BASE_URL + url.API_PLAN_SUBSCRIPTION_CANCEL;
            
            const subscriptionData = {
                sid: subscriptionId,
                email: newCustomerEmail
            }    

            const response = await postData(requestURL, subscriptionData);

            console.log('subscription cancel response', response);

            if (response.status) {
                getDetailsOfSubscription();
                setNewCustomerEmail('');
            }

        } catch (error) {
            console.log(error)
        }
    };

   
    // get subscription details
    useEffect(()=>{
        console.log('subscriptionId', subscriptionId);
        getDetailsOfSubscription()

    }, [subscriptionId])

    // show cancel button only when valid email is entered
    useEffect(()=>{
        if(customerEmail !== '' && newCustomerEmail !== ''){
            if(customerEmail === newCustomerEmail){
                setSubscriberValidate(true);
            }else{
                setSubscriberValidate(false);
            }
        }
    }, [newCustomerEmail])


    return (
        <main id="app">
        
            <header className="cart_header py-3 bg-white shadow-sm">
                <div className="container-fluid  px-lg-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="header_logo">
                            <Link to={invoiceBillingAddress.website} className="d-inline-block">
                                <img src={invoiceBillingAddress.logourl} className="w-50" alt="" />
                            </Link>
                        </div>
                        <div className="login_btn">
                            <Link to={invoiceBillingAddress.website} className="btn btn-outline-primary">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </header>
        
            <div id="content_wrapper">
                {subscriptionDetails &&
                    (
                        <section className="cart_wrapper py-4">
                            <div className="container-fluid px-lg-5">
                                <div className="row">
                                    <div className="col-lg-8 mb-sm-4">
                                        <div className="cart_lftpnl bg-white shadow-sm rounded-10 p-4">

                                            <div className="cart_top_info pb-4 mb-1">
                                            
                                            
                                                <h3 className="fw-semibold fs-xl mb-2 text-primary">
                                                    <span className='text-black'>Subscription :</span> 
                                                    {subscriptionDetails.plan?.title??''}
                                                </h3>


                                                
                                                <ul className="list_style_none d-flex gap-4 mb-1">
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">Status:</label>
                                                        
                                                            {isCancelled ? 
                                                                (<span className="text-primary fw-bold">
                                                                    Cancelled
                                                                </span>
                                                                ):
                                                                (<span className="text-success fw-bold">
                                                                    Active
                                                                </span>
                                                                )
                                                            }
                                                        
                                                    </li>
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">Duration:</label>
                                                        <span className="text-primary">
                                                            {subscriptionDetails.planduration ? subscriptionDetails.planduration : 1} {' '}
                                                            {subscriptionDetails.planduration > 1 ? 'Months' : ' Month'}
                                                        </span>
                                                    </li>
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">No. of Tickets:</label>
                                                        <span className="text-primary">
                                                            {subscriptionDetails.plan?.persons??''} Persons
                                                        </span>
                                                    </li>
                                                </ul>

                                                <ul className="list_style_none d-flex gap-4 mb-1">
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">Start Date:</label>
                                                        <span className="text-primary">
                                                            {getDateFormatInSpanish(subscriptionDetails.startdate)}
                                                        </span>
                                                    </li>
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">End Date:</label>
                                                        <span className="text-primary">
                                                            {getDateFormatInSpanish(subscriptionDetails.enddate)}
                                                        </span>
                                                    </li>
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">Last Payment Date:</label>
                                                        <span className="text-primary">
                                                            {getDateFormatInSpanish(subscriptionDetails.lastpaymentdate)}
                                                        </span>
                                                    </li>
                                                    <li className="d-flex align-items-center gap-2  text-black">
                                                        <label className="text-gray m-0">Next Due Date:</label>
                                                        <span className="text-primary">
                                                            {getDateFormatInSpanish(subscriptionDetails.nextpaymentduedate)}
                                                        </span>
                                                    </li>
                                                </ul>

                                            </div>
                                            
                                            <hr/>

                                        
                                            <div className="cart_personal_info">
                                                <div className="information_item">
                                                    <p className="fw-semibold mb-2 text-black">
                                                        Dear {subscriptionDetails.customer?.name??''}

                                                    </p>
                                                    {isCancelled ?
                                                        <p className=''>
                                                            The subscription is cancelled for renewal. Incase you want to renew the subscription feel free to visit <a href='https://club.elpavonteatro.es/' className='text-primary'>club.elpavonteatro.es</a>    
                                                        </p>
                                                        :(

                                                          <>
                                                            <p>
                                                                Before you finalize your decision, we wanted to thank you for purchasing the subscription as your participation means a lot to us.
                                                            </p>
                                                            <p>
                                                            Is there anything we can do to make your experience better? 
                                                            Your feedback is incredibly valuable to us, and we're committed to improving our services to meet your needs.
                                                            </p>
                                                            <p>For feedback write to <span className='text-danger'>feedback@teatrosllenos.com</span> </p>

                                                            <div className="row mt-3">

                                                                <p className='mb-2'>If still you want to cancel subscription, enter your registered email id and continue</p>
                                                                
                                                                <div className="col-lg-6 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                                    <div className="from-group">
                                                                        <label className="d-block fs-sm fw-semibold mb-2">Email *</label>
                                                                        <input type="email" 
                                                                            className="form-control fs-sm shadow-none" 
                                                                            placeholder="Enter Email" 
                                                                            onChange={(e) => { 
                                                                                setNewCustomerEmail(e.target.value);  
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>   

                                                                <div className="col-lg-6 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                                    {subscriberValidate  && (
                                                                        <div className="order_btn mt-4">
                                                                            <button
                                                                                onClick={cancelSubscription}
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                style={{ cursor: loading ? "not-allowed" : "pointer" }}
                                                                                disabled={loading ? true : false}
                                                                                >
                                                                                {t("Cancel Subscription")}
                                                                                {loading && (
                                                                                    <div
                                                                                    className="mx-2 spinner-border spinner-border-sm"
                                                                                    role="status"
                                                                                    >
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                    </div>
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>  

                                                                                                                
                                                            </div>
                                                          </>  
                                                        )
                                                    }
                                                    

                                                </div>
                                            </div>
                                            
                                    

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                    )
                
                }
                

            </div>

        </main>
    );

};

export default SubscriptionCancel;
