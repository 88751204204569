/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

//import membership options from json
import statusOption from "data/Prod/MembershipStatus.json";
import { useTranslation } from "react-i18next";

const CertificateDetailsPopup = ({
  selectedSubscriberId = null,
  setSelectedSubscriberId,
  afterPopupClose,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [courseSubsriberId, setCourseSubsriberId] = useState(null);
  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [certificateList, setCertificateList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  //function for get details of event member
  const getSubscriberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CERTIFICATE_DETAILS +
        `/${selectedSubscriberId}?token=${token}`;

      const response = await getData(requestUrl);
      console.log(response);

      if (response.status) {
        setCourseSubsriberId(response.data.coursesubscriberid);
        setSubscriberName(response.data.name);
        setSubscriberEmail(response.data.email);
        setCertificateList(response.data.certificatelist);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for update subscriber
  const updateSubscriberHandler = async () => {
    const todayValue = new Date().toISOString().split("T")[0];

    setIsSaving(true);
    try {
      let subscriberData = {
        certificationdate: todayValue,
        iscertified: true,
      };

      // console.log(memberData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_COURSE_SUBSCRIBER +
        `/${selectedSubscriberId}?token=${token}`;

      const response = await putData(requestUrl, subscriberData);

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        let offCanvasPopup = document.querySelector("#certificateDetails");
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();

        closePopupHandler();
        afterPopupClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for after close popup
  const closePopupHandler = () => {
    setSelectedSubscriberId(null);
    setCourseSubsriberId(null);
    setSubscriberName("");
    setSubscriberEmail("");
    setCertificateList([]);
  };

  useEffect(() => {
    if (selectedSubscriberId) {
      getSubscriberDetails();
    }
  }, [selectedSubscriberId]);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="certificateDetails"
      aria-labelledby="certificateDetailsLabel"
    >
      <div className="offcanvas-header gradient-light p-4">
        <div>
          <h3 className="offcanvas-title mb-2" id="certificateDetailsLabel">
            {subscriberName}
          </h3>
          <ul className="task-category d-flex flex-wrap">
            <li className="position-relative">{subscriberEmail}</li>
          </ul>
        </div>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closePopupHandler}
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-0">
        <div className="task-details flex-fill p-4">
          <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
            {/* <!-- ========== Start invoice item table ========== --> */}
            <div className="table-responsive mb-1">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      width="30%"
                      className="description bg-transparent fs-md fw-semibold border-0"
                    >
                      {t("Lesson")}
                    </th>
                    <th className="quantity bg-transparent fs-md fw-semibold border-0">
                      {t("No of Tasks")}
                    </th>
                    <th className="amt bg-transparent fs-md fw-semibold border-0">
                      {t("Total Score")}
                    </th>
                    <th className="taxes bg-transparent fs-md fw-semibold border-0">
                      {t("Obtained Score")}
                    </th>
                  </tr>
                </thead>
                <tbody style={{ minHeight: "200px" }}>
                  {certificateList.map((certificate, index) => {
                    return (
                      <tr style={{ verticalAlign: "top" }} key={index}>
                        <td className="description border-bottom-0 p-1">
                          <p className="form-control fs-sm shadow-none">
                            {certificate.title}
                          </p>
                        </td>
                        <td className="quantity border-bottom-0 p-1">
                          <p className="form-control fs-sm shadow-none">
                            {certificate.totaltaskcount}
                          </p>
                        </td>
                        <td className="amt border-bottom-0 p-1">
                          <p className="form-control fs-sm shadow-none">
                            {certificate.totalscore}
                          </p>
                        </td>
                        <td className="amt border-bottom-0 p-1">
                          <p className="form-control fs-sm shadow-none">
                            {certificate.obtainedscore}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <!-- ========== End invoice item table ========== --> */}
          </div>
        </div>
        <form onSubmit={(e) => e.preventDefault()} className="p-4 border-top">
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateSubscriberHandler}
            >
              {t("Approve")}
            </button>
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Decline")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CertificateDetailsPopup;
