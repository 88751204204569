/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";
import { ecosystemSlug, invoiceOwnerEmail, invoiceBillingAddress } from "Config/Config";
import { useTranslation } from "react-i18next";
import { generateRecurringInvoice, processRecurringPayment } from "helper/RedsysHelper/RedsysHelper";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
const ShowPaymentPopup = ({
  afterSave,
  selectedSubscriptionId,
  resetSubscriptionId,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // get subscription payment details
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);

  // data to generate new invoice
  const [invoiceProviderEmail, setInvoiceProviderEmail] = useState(invoiceOwnerEmail); // owner of invoice
  const [invoiceClientEmail, setInvoiceClientEmail] = useState(''); // customer of invoice
  //const ecosystemSlug = 'MUSAMERDA'
  const [invoicePlanId, setInvoicePlanId] = useState(''); // plan id to be added in invoice

  // get invoice id for recurring payment
  const [recurringPaymentInvoiceId, setRecurringPaymentInvoiceId] = useState("");
  const [recurringPaymentInvoiceCustomId, setRecurringPaymentInvoiceCustomId] = useState("");

  const [isProcessing, setIsProcessing] = useState(false)

  //get details of list
  const getDetailsOfSubscription = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_SUBSCRIPTION_DETAILS + `/${selectedSubscriptionId}?token=${token}`;
        
      const response = await getData(requestURL);

      console.log('subscription Details', response);

      if (response.status) {

        setSubscriptionDetails(response.data); 

        setInvoiceProviderEmail(response.data?.owner?.email??invoiceOwnerEmail);

        setInvoiceClientEmail(response.data?.customer?.email??'');

        setInvoicePlanId(response.data?.plan?._id??'');
       
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //process recurring payment
  // 1st generate invoice and get recurringPaymentInvoiceId
  // 2nd - process the recurring payment request 
    const requestDuePayment = async() =>{
        console.log('request due payment');

        setIsProcessing(true);

        //const generateInvoiceId = await generateRecurringInvoice();
        const customOrderId = await generateRecurringInvoice(ecosystemSlug, 
                                                        invoicePlanId,
                                                        invoiceClientEmail,
                                                        invoiceProviderEmail                                                    
                                                    );

        console.log('customOrderId >>>',customOrderId);

        if(customOrderId && selectedSubscriptionId && customOrderId.length > 2){
            const recurringPay = await processRecurringPayment(customOrderId, 
                                                                selectedSubscriptionId);


            console.log('recurringPay >>>', recurringPay);

            setIsProcessing(false);
            
            // refresh details
            getDetailsOfSubscription();
        }
    }

    // Generate Invoice
    // const generateRecurringInvoice = async() =>{

    //     try {

    //         setIsProcessing(true);

    //         const data = {
    //             ecosystemslug : ecosystemSlug,
    //             planid: invoicePlanId,
    //             email: invoiceClientEmail,
    //             invoiceowner: invoiceProviderEmail
    //         }

    //         console.log('invoice form data', data);

    //         let requestUrl = url.API_BASE_URL +
    //                         url.API_CREATE_GUEST_INVOICE;

    //         const response = await postData(requestUrl, data);

    //         console.log('invoice response', response);

    //         if(response.status){

    //             setRecurringPaymentInvoiceId(response.data._id);

    //             setRecurringPaymentInvoiceCustomId(response.data.invoiceid);


    //             // request for recurring payment
    //             if(response.data && response.data.invoiceid.length > 1){
    //                 const recurringPay = processRecurringPayment(response.data.invoiceid);
    //             }
                                
    //             //setIsProcessing(false);
    //         }
                
    //     } catch (error) {

    //            console.log('Error', error); 

    //     }
    // }

    // API_REDSYS_RECURRING_PAYMENT
    // const processRecurringPayment = async (customOrderId) => {

    //     try {

    //             const data = {
    //                 invoicecustomid: customOrderId,
    //                 subscriptionid: selectedSubscriptionId
    //             }

    //             console.log('recurring form data', data);

    //             if(customOrderId && selectedSubscriptionId){

    //                 let requestUrl = url.API_BASE_URL +
    //                             url.API_REDSYS_RECURRING_PAYMENT;

    //                 const response = await postData(requestUrl, data);

    //                 console.log('recurring response', response);

    //                 if(response.status){
                        
    //                     setIsProcessing(false);

    //                     // refresh the payment details if recurring payment processed
    //                     getDetailsOfSubscription();

    //                 }

    //             }
                
                    
    //         } catch (error) {

    //             console.log('Error', error); 

    //         }

    // };



    const resetHandler = () => {
        resetSubscriptionId();
        setIsProcessing(false);
    };

    useEffect(() => {

        if (selectedSubscriptionId) {
            console.log('selected subscription', selectedSubscriptionId)
            getDetailsOfSubscription();
        }

    }, [selectedSubscriptionId]);

    return (
        <div
        className="offcanvas lg offcanvas-end bg-white border-0 w-75"
        tabIndex="-1"
        id="offcanvasSaveList"
        aria-labelledby="offcanvasUpdateListLabel"
        >
        <div className="offcanvas-header p-4 pb-0">
            <h3 className="offcanvas-title" id="offcanvasUpdateListLabel">
            {t("Subscription Details")}
            </h3>
            <button
            type="button"
            className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={resetHandler}
            ></button>
        </div>
        <div className="offcanvas-body p-4">


                <div className="offcanvas-body d-flex flex-column p-0">

                    <div className="offcanvas-body d-flex flex-row flex-wrap p-0 col-12">
                        <div className="col-12 col-md-4 ps-4">
                            <h3 className="offcanvas-title mb-2">
                                Plan : {subscriptionDetails.plan?.title??''} 
                            </h3>
                            <ul className="task-category">
                                <li className="position-relative">Tickets/Persons: {subscriptionDetails.plan?.persons??''} </li>
                    
                                <li className="position-relative">Duration (Month): {subscriptionDetails.planduration ? subscriptionDetails.planduration : "1" } </li>
                                <li className="position-relative">Start Date: {getDateFormatInSpanish(subscriptionDetails.startdate)} </li>
                                <li className="position-relative">End Date: {subscriptionDetails.enddate ? getDateFormatInSpanish(subscriptionDetails.enddate) : getDateFormatInSpanish(subscriptionDetails.nextpaymentduedate)} </li>

                                <li className="position-relative">Status: {subscriptionDetails.iscancelled ? 
                                                                                    (<span className="text-danger fw-bold">Cancelled</span>) : 
                                                                                    (<span className="text-success fw-bold">Active</span>)
                                                                                    } 

                                </li>
                            </ul>
                        </div>

                        <div className="col-12 col-md-4 ps-4">
                            <h6 className="offcanvas-title mb-2">
                            Customer
                            </h6>
                            <ul className="task-category">
                                <li className="position-relative">{subscriptionDetails.customer?.name??''} {' '} {subscriptionDetails.customer?.surname??''}  </li>
                                <li className="position-relative">{subscriptionDetails.customer?.email??''} </li>
                                <li className="position-relative">{subscriptionDetails.customer?.phone??''} </li>
                                <li className="position-relative">{subscriptionDetails.customer?.address??''} {' '} {subscriptionDetails.customer?.country??''} {' '} {subscriptionDetails.customer?.zipcode??''} </li>
                            </ul>
                        </div>

                        <div className="col-12 col-md-4 ps-4">
                            <h6 className="offcanvas-title mb-2">
                            Provider
                            </h6>
                            <ul className="task-category">
                                <li className="position-relative">{subscriptionDetails.owner?.name??''} {' '} {subscriptionDetails.owner?.surname??''}  </li>
                                <li className="position-relative">{subscriptionDetails.owner?.email??''} </li>
                            </ul>
                        </div>
                    </div>
                    <div className="offcanvas-body ps-4">  
                        <h6 className="text-danger fw-bold">Due date: {getDateFormatInSpanish(subscriptionDetails.nextpaymentduedate)}</h6>
                    </div>

                    <div className="offcanvas-body d-flex flex-row flex-wrap ps-4 col-12">                    
                        {subscriptionDetails.iscancelled?'':
                        (
                        <button type="button" className="btn btn-primary" onClick={requestDuePayment}>
                            {isProcessing && (
                                <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                                >
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                            
                            Request Next Due Payment
                        </button>
                        )}

                        {subscriptionDetails.iscancelled?'':
                        (
                            <Link to={`/auth/subscription/cancel/${selectedSubscriptionId}`}
                                    className="btn btn-outline-primary ms-auto"
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    >
                                Cancel Subscription
                            </Link>
                        )}
                        
                        
                    </div>
                    
                    

                    <div className="flex-fill p-4">

                        <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
                            {/* <!-- ========== Start invoice item table ========== --> */}
                            <div className="table-responsive mb-1">
                                <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                                    <thead>
                                    <tr>
                                        
                                        <th className="quantity bg-transparent fs-md fw-semibold border-0">
                                        {t("Amount")} &euro;
                                        </th>
                                        <th className="amt bg-transparent fs-md fw-semibold border-0">
                                        {t("Ref. Id")}
                                        </th>
                                        <th className="amt bg-transparent fs-md fw-semibold border-0">
                                        {t("Source")}
                                        </th>
                                        <th className="taxes bg-transparent fs-md fw-semibold border-0">
                                        {t("Payment Date")}
                                        </th>
                                        <th width="30%" className="description bg-transparent fs-md fw-semibold border-0">
                                        {t("Payment Status")}
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody style={{ minHeight: "200px" }}>
                                    {subscriptionDetails.payments && 
                                        subscriptionDetails.payments.map((payment, index) => {
                                        // Convert payment.created to a Date object

                                        // Extract date in YYYY-MM-DD format
                                        const createddate = payment.createdAt.split('T')[0];

                                        // Extract time in HH:MM:SS format
                                        const createdtime = payment.createdAt.split('T')[1].split('.')[0];
                                    
                                        return (
                                            
                                            <tr style={{ verticalAlign: "top" }} key={index}>
                                                
                                                <td className="quantity border-bottom-0 p-1">
                                                    <p className="form-control fs-sm shadow-none">
                                                    &euro; {payment.amount / 100}
                                                    </p>
                                                </td>
                                                <td className="amt border-bottom-0 p-1">
                                                    <p className="form-control fs-sm shadow-none">
                                                        {payment.referenceid}
                                                    </p>
                                                </td>
                                                <td className="amt border-bottom-0 p-1">
                                                    <p className="form-control fs-sm shadow-none">
                                                        {payment.source}
                                                    </p>
                                                </td>
                                                <td className="amt border-bottom-0 p-1">
                                                    <p className="form-control fs-sm shadow-none">
                                                        {createddate} {' : '} {createdtime}
                                                    </p>
                                                </td>
                                                <td className="amt border-bottom-0 p-1">
                                                    <p className="form-control text text-success fw-bold fs-sm shadow-none">
                                                        PAID
                                                    </p>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- ========== End invoice item table ========== --> */}
                        </div>
                        
                    </div>

                    
                    {/* <form onSubmit={(e)=> e.preventDefault()} className="p-4 border-top">
                        <div className="action d-flex flex-wrap gap-3 justify-content-between">
                            <button type="button" className="btn btn-primary" onClick={updateSubscriberHandler}>
                            {t("Approve")}
                            </button>
                            <Link to="#" className="btn btn-outline-primary" data-bs-dismiss="offcanvas" aria-label="Close">
                            {t("Decline")}
                            </Link>
                        </div>
                    </form> */}


                </div>

            
        </div>
        </div>
    );
};
export default ShowPaymentPopup;
