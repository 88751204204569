/* eslint-disable */
import Header from 'components/Common/Header/Header';
import TicketListBody from 'components/TicketsComponents/TicketsListBody/TicketsListBody';
import TicketCustomerListBody from 'components/TicketsComponents/CustomersListBody/CustomersListBody';
import React, { useEffect } from 'react';

const Tickets = () => {

  // -------- for title ------------
  useEffect(() => {
    document.title = 'Tickets';
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="tickets" />

      {/* --------- body section ------------ */}
      <TicketListBody />
      

    </main>
  );
};

export default Tickets;
