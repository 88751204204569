/* eslint-disable */
import React, { useEffect } from "react";

//** import components */
import Header from "components/Common/Header/Header";
import TicketDashboardBody from "components/DashboardComponents/DashboardBody/TicketDashboardBody";
import DashboardReportIssueModal from "components/DashboardComponents/Modal/DashboardReportIssueModal";
//import AddLabelModal from "components/Common/Modal/AddLabelModal";
import DashboardSendOfferModal from "components/DashboardComponents/Modal/DashboardSendOfferModal";

const Dashboard = () => {
  //assign browser title is dashboard
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  return (
    <main id="app">
      <Header moduleName="dashboard" />

      {/* <!-- ========== Start main dashboard component Section ========== --> */}
      <TicketDashboardBody />
      {/* <!-- ========== End main dashboard component Section ========== --> */}

      {/* <!-- ========== Start popup and modal components ========== --> */}
      
      {/* <AddLabelModal moduleName="dashboard" /> */}
      <DashboardReportIssueModal />
      <DashboardSendOfferModal />
      {/* <!-- ========== End popup and modal components ========== --> */}
    </main>
  );
};

export default Dashboard;
