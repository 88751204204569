/* eslint-disable */
import React, { useState, useEffect } from "react";
import { assetImages } from "constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


/*import url and gateway methods */
import { getData, postData } from "utils/Gateway";
import { ecosystemSlug, invoiceOwnerEmail, invoiceBillingAddress } from "Config/Config";
import * as url from "helper/UrlHelper";
import { generateRecurringInvoice, processRecurringPayment, redsysPaymentWait } from "helper/RedsysHelper/RedsysHelper";


const ConfirmBulkPayment = ({ 
    selectedIds,
    afterModalClose,
    setShowAlert,
    setAlertMessage,
    setMessageType,
 }) => {

    const token = localStorage.getItem('token');
    /* ------------- Language translation imports starts here ------------- */
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false);


    //process massive action for recurring payments ------------------------------
    const processBulkPaymentHandler = async () => {

        console.log('selected ids for bulk payment', selectedIds);

        if (selectedIds.length > 0) {

            try {

                // run a loop for all selected components or checkboxId
                if(selectedIds.length > 0){

                    setIsProcessing(true);

                    const promises = selectedIds.map(async (selectedId) => {

                        console.log('selected id', selectedId);

                        // get subscription details
                        // request custom id
                        // process recurring payment
                        let requestURL =
                            url.API_BASE_URL + url.API_GET_SUBSCRIPTION_DETAILS + `/${selectedId}?token=${token}`;
                            
                        const response = await getData(requestURL);

                        if (response.status) {
                            
                            console.log('request due payment');

                            const invoicePlanId = response.data?.plan?._id??null;

                            const invoiceClientEmail = response.data?.customer?.email??null;
                            
                            const invoiceProviderEmail = response.data?.owner?.email??invoiceOwnerEmail;


                            if(ecosystemSlug && invoicePlanId && invoiceClientEmail && invoiceProviderEmail){
                                
                                const customOrderId = await generateRecurringInvoice(ecosystemSlug, 
                                                                                invoicePlanId,
                                                                                invoiceClientEmail,
                                                                                invoiceProviderEmail                                                    
                                                                            );

                                console.log('customOrderId >>>',customOrderId);

                                if(customOrderId && selectedId && customOrderId.length > 2){

                                    const recurringPay = await processRecurringPayment(customOrderId, 
                                                                                        selectedId);


                                    console.log('recurringPay >>>', recurringPay);

                                    
                                }
                            }                        
                                            
                        }

                    })

                    // Wait for all promises to complete
                    await Promise.all(promises);

                    // Set isProcessing to false once all promises are resolved
                    setIsProcessing(false);

                    // close the modal           
                    let selectedModal = document.querySelector('#recurringPaymentConfirmModal');
                    let modal = bootstrap.Modal.getInstance(selectedModal);
                    modal.hide();

                    afterModalClose();
                }

            } catch (error) {
                setShowAlert(true);
                setAlertMessage(error.message);
                setMessageType('error');
            }
        
        } else {
            setAlertMessage("Please select a subscription");
            setMessageType("error");
        }

        setShowAlert(true);
    };
    // ---------------------------------------------------------------------------

    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  return (
    <div
      className="modal fade"
      id="recurringPaymentConfirmModal"
      tabIndex="-1"
      aria-labelledby="recurringPaymentCofirmModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="warning"
              className="img-fluid"
            />
            <h2>{t("Confirming will execute recurring payments")}?</h2>
            {/* <h3>** Task with logs can't be deleted</h3> */}
            <div className="d-flex gap-3 justify-content-center">

                {isProcessing && (
                    <>
                        <div className="btn btn-primary">
                            <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                            >
                            <span className="visually-hidden">Loading...</span>
                            </div>
                            {t("Waiting for response")}
                        </div>

                        <div
                            className="btn btn-otline-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={afterModalClose}
                        >
                            {t("Close & continue")}
                        </div>
                    </>
                )}

                {isProcessing === false && (

                    <>
                        <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={processBulkPaymentHandler}
                        >
                            {t("Yes, initiate payment request")}
                        </Link>
                        <Link
                            to="#"
                            className="btn btn-otline-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={afterModalClose}
                        >
                            {t("No, Cancel")}
                        </Link>
                    </>

                )}              
              


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBulkPayment;
