/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams, useLocation  } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";
import { ecosystemSlug, invoiceOwnerEmail, invoiceBillingAddress } from "Config/Config";
import { useTranslation } from "react-i18next";


const GuestPayment = () => {

    const { t } = useTranslation(); //for translation
    const params = useParams();
    const location = useLocation();
    const planParam = new URLSearchParams(location.search).get('plan');


    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    // plan states ----
    const [planId, setPlanId] = useState(planParam);
    const [planTitle, setPlanTitle] = useState('');
    const [planPrice, setPlanPrice] = useState(0);
    const [planDuration, setPlanDuration] = useState(0);
    const [planPerson, setPlanPerson] = useState('');

    // invoice states
    const [amount, setAmount] = useState(0);
    const [orderId, setOrderId] = useState('');
    const [customOrderId, setCustomOrderId] = useState('');
    const [loading, setLoading] = useState(false);

    // customer states
    const [customerid, setCustomerid] = useState('');
    const [customername, setCustomername] = useState('');
    const [customersurname, setCustomersurname] = useState('');
    const [customeremail, setCustomeremail] = useState('');

    const [customerphone, setCustomerphone] = useState('');
    const [customercompany, setCustomercompany] = useState('');
    const [customeraddress, setCustomeraddress] = useState('');

    const [customercity, setCustomercity] = useState('');
    const [customercountry, setCustomercountry] = useState('');
    const [customerzipcode, setCustomerzipcode] = useState('');
    const [agree, setAgree] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // redsys states
    const [DsSignatureVersion, setDsSignatureVersion] = useState('');
    const [DsMarchantParams, setDsMarchantParams] = useState('');
    const [DsSignature, setDsSignature] = useState('');
    const [redsysUrl, setRedsysUrl] = useState('');

    // get plan id and set the price and show plan details
    const getPlanDetails = async () => {
        try {
            let requestUrl =
                url.API_BASE_URL +
                url.API_GET_PLAN_DETAILS +
                `/${planId}`;
            
            console.log('plan request', requestUrl);

            const response = await getData(requestUrl);

            console.log('plan response', response);

            if (response.status) {
                setPlanTitle(response.data.title);
                setPlanPrice(response.data.price);
                setAmount(response.data.price);
                setPlanDuration(response.data.duration);
                setPlanPerson(response.data.persons);
            }

        } catch (error) {
            console.log(error.message);
        }
    };

    /* hook for validation */
    const [validation, setValidation] = useState({
        nameWarning: false,
        surnameWarning: false,
        emailWarning: false,
        phoneWarning: false,
        addressWarning: false,
        cityWarning: false,
        countryWarning: false,
        zipcodeWarning: false,
        agreeWarning: false,
    });

    /* function to validate form */
    const validate = () => {
        let isValid = true;

        if (customername === "") {
            setValidation((prevState) => ({ ...prevState, nameWarning: true }));
            isValid = false;
        }

        if (customersurname === "") {
            setValidation((prevState) => ({ ...prevState, surnameWarning: true }));
            isValid = false;
        }

        const isValidEmail = validateEmail(customeremail);
        if (!isValidEmail) {
            setValidation((prevState) => ({ ...prevState, emailWarning: true }));
            isValid = false;
        }        

        if (customerphone === "") {
            setValidation((prevState) => ({ ...prevState, phoneWarning: true }));
            isValid = false;
        }

        if (customeraddress === "") {
            setValidation((prevState) => ({ ...prevState, addressWarning: true }));
            isValid = false;
        }

        if (customercity === "") {
            setValidation((prevState) => ({ ...prevState, cityWarning: true }));
            isValid = false;
        }

        if (customercountry === "") {
            setValidation((prevState) => ({ ...prevState, countryWarning: true }));
            isValid = false;
        }

        if (customerzipcode === "") {
            setValidation((prevState) => ({ ...prevState, zipcodeWarning: true }));
            isValid = false;
        }

        if (agree === false) {
            setValidation((prevState) => ({ ...prevState, agreeWarning: true }));
            isValid = false;
        }

        return isValid;
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    // send data to create guest invoice and return the invoice details
    const saveBillingHandler = async() => {

        const isValid = validate();
        console.log('form is valid', isValid);

        if(isValid){

            try {
                setIsSaving(true);
                const data = {
                    orderid: orderId,
                    ecosystemslug : ecosystemSlug,
                    planid: planId,
                    name: customername,
                    surname: customersurname,
                    email: customeremail,
                    phone: customerphone,
                    company: customercompany,
                    address: customeraddress,
                    city: customercity,
                    country: customercountry,
                    zipcode: customerzipcode,
                    invoiceowner: invoiceOwnerEmail
                    
                }

                console.log('form data', data);

                let requestUrl = url.API_BASE_URL +
                                url.API_CREATE_GUEST_INVOICE;

                const response = await postData(requestUrl, data);

                console.log('response', response);

                if(response.status){

                    setOrderId(response.data._id);

                    setCustomOrderId(response.data.invoiceid);

                    setCustomerid(response.user);

                    setIsSaving(false);

                    if(response.data._id){
                        redsysConfig(response.data.invoiceid, response.data._id);
                    }
                    
                }
                
            } catch (error) {

            }
        }
        
    };


    // get redsys configuration params from controller, with necessary data to be sent with form
    const redsysConfig = async (customorderid, invoiceid) => {

        console.log('redsys order id', customorderid);

        try {

            //let requestURL = url.API_BASE_URL + url.API_REDSYS_CREATE_PAYMENT;
            let requestURL = url.API_BASE_URL + url.API_REDSYS_SUBSCRIPTION_PAYMENT;
 
            
            console.log('redsys create payment url', requestURL);

            const redsysData = {
                amount,
                customorderid,
                invoiceid
            }

            console.log('redsysData', redsysData);

            const response = await postData(requestURL, redsysData);

            console.log('redsys payment response', response);

            if(response){
                //setRedsysUrl(response.redsysUrl);
                setDsSignatureVersion(response.signatureversion);
                setDsMarchantParams(response.merchantParameters);
                setDsSignature(response.signature);
            }
            
            //console.log('redsysUrl', redsysUrl);
            // Create a form and submit it to Redsys
            

        } catch (error) {
        console.error('Payment error:', error);
        } finally {
        setLoading(false);
        }
    };

    // useEffect(()=>{

    //     if(amount && orderId){
    //         redsysConfig();
    //     }        
    
    // }, [amount, orderId])

    useEffect(()=>{
        console.log('planId useEffect', planId);
        getPlanDetails()

    }, [planParam])


    return (
        <main id="app">
        
            <header className="cart_header py-3 bg-white shadow-sm">
                <div className="container-fluid  px-lg-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="header_logo">
                            <Link to={invoiceBillingAddress.website} className="d-inline-block">
                                <img src={invoiceBillingAddress.logourl} className="w-50" alt="" />
                            </Link>
                        </div>
                        <div className="login_btn">
                            <Link to={invoiceBillingAddress.website} className="btn btn-outline-primary">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </header>
        
            <div id="content_wrapper">
                
                <section className="cart_wrapper py-4">
                    <div className="container-fluid px-lg-5">
                        <div className="row">
                            <div className="col-lg-8 mb-sm-4">
                                <div className="cart_lftpnl bg-white shadow-sm rounded-10 p-4">

                                    <div className="cart_top_info pb-4 mb-1">
                                    
                                    
                                        <h3 className="fw-semibold fs-xl mb-2 text-primary"><span className='text-black'>Subscription :</span> {planTitle}</h3>
                                        
                                        <ul className="list_style_none d-flex gap-4 mb-1">
                                            <li className="d-flex align-items-center gap-2  text-black">
                                                <label className="text-gray m-0">Duration:</label>
                                                <span className="text-primary">{planDuration} Days</span>
                                            </li>
                                            <li className="d-flex align-items-center gap-2  text-black">
                                                <label className="text-gray m-0">No. of Tickets:</label>
                                                <span className="text-primary">{planPerson}</span>
                                            </li>
                                        </ul>

                                        {/* <div className="discount_tag rounded-8 px-2 py-1 fs-sm text-green d-inline-block">
                                            25% Discount
                                        </div> */}

                                    </div>
                                    
                                    <hr/>

                                 
                                    <div className="cart_personal_info">
                                        <div className="information_item">
                                            <h3 className="fw-semibold fs-xl mb-2 text-black">Billing Details</h3>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Name </label>
                                                        <input type="text" 
                                                            className="form-control fs-sm shadow-none" 
                                                            placeholder="Enter First Name" 
                                                            onChange={(e) => {
                                                                                setCustomername(e.target.value);

                                                                                setValidation((prevState) => ({
                                                                                    ...prevState,
                                                                                    nameWarning: false,
                                                                                }));
                                                                            }}  
                                                        />
                                                        {/* warning */}
                                                        {validation.nameWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter your name")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Surname </label>
                                                        <input type="text" 
                                                            className="form-control fs-sm shadow-none" 
                                                            placeholder="Enter Last Name" 
                                                            onChange={(e) => { setCustomersurname(e.target.value) 
                                                                
                                                                setValidation((prevState) => ({
                                                                                    ...prevState,
                                                                                    surnameWarning: false,
                                                                                }));
                                                            }}
                                                        />
                                                        {/* warning */}
                                                        {validation.surnameWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter surname")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Email </label>
                                                        <input type="email" 
                                                            className="form-control fs-sm shadow-none" 
                                                            placeholder="Enter Email" 
                                                            onChange={(e) => { setCustomeremail(e.target.value) 

                                                                setValidation((prevState) => ({
                                                                                    ...prevState,
                                                                                    emailWarning: false,
                                                                                }));
                                                            }}
                                                        />

                                                        {/* warning */}
                                                        {validation.emailWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter valid email")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>                                        
                                                
                                                <div className="col-lg-6 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Phone </label>
                                                        <input type="number" 
                                                        className="form-control fs-sm shadow-none" 
                                                        placeholder="Enter Phone No" 
                                                        onChange={(e) => { setCustomerphone(e.target.value) 
                                                            setValidation((prevState) => ({
                                                                                    ...prevState,
                                                                                    phoneWarning: false,
                                                                                }));
                                                        }}
                                                        />
                                                        {/* warning */}
                                                        {validation.phoneWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter a phone number")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Company</label>
                                                        <input type="text" 
                                                        className="form-control fs-sm shadow-none" 
                                                        placeholder="Enter Company Name"
                                                        onChange={(e) => { setCustomercompany(e.target.value) 

                                                        }}
                                                        
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-8 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Address</label>
                                                        <input type="text" 
                                                        className="form-control fs-sm shadow-none" 
                                                        placeholder="Enter Communication Address" 
                                                        onChange={(e) => { setCustomeraddress(e.target.value) 
                                                            setValidation((prevState) => ({
                                                                                    ...prevState,
                                                                                    addressWarning: false,
                                                                                }));
                                                        }}
                                                            
                                                        />
                                                        {/* warning */}
                                                        {validation.addressWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter address communication")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>                        

                                                <div className="col-lg-4 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Town/ City </label>
                                                        <input type="text" 
                                                        className="form-control fs-sm shadow-none" 
                                                        placeholder="Enter Your City" 
                                                        onChange={(e) => { setCustomercity(e.target.value) 
                                                            setValidation((prevState) => ({
                                                                                    ...prevState,
                                                                                    cityWarning: false,
                                                                                }));
                                                        }}
                                                        />
                                                        {/* warning */}
                                                        {validation.cityWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter city")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">Country </label>
                                                        <input type="text" 
                                                        className="form-control fs-sm shadow-none" 
                                                        placeholder="Enter Country" 
                                                        onChange={(e) => { setCustomercountry(e.target.value)
                                                                setValidation((prevState) => ({
                                                                                        ...prevState,
                                                                                        countryWarning: false,
                                                                                    }));
                                                        }}
                                                        />
                                                        {/* warning */}
                                                        {validation.countryWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter country")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-4 col-md-6 mb-3 mb-sm-4 mb-lg-3">
                                                    <div className="from-group">
                                                        <label className="d-block fs-sm fw-semibold mb-2">ZIP Code </label>
                                                        <input type="text" 
                                                        className="form-control fs-sm shadow-none" 
                                                        placeholder="Enter Code" 
                                                        onChange={(e) => { setCustomerzipcode(e.target.value) 
                                                            setValidation((prevState) => ({
                                                                                        ...prevState,
                                                                                        zipcodeWarning: false,
                                                                                    }));
                                                        }}
                                                        />
                                                        {/* warning */}
                                                        {validation.zipcodeWarning && (
                                                            <div className="error-message mt-2">
                                                            <p className="d-flex align-items-center gap-1 text-danger">
                                                                <span className="material-symbols-outlined">
                                                                warning
                                                                </span>
                                                                <span>{t("Please enter zipcode")}!</span>
                                                            </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {DsSignature == ''  && (
                                    <div className="form-check d-flex align-items-center gap-2 fs-sm text-gray p-0 m-0">
                                        <input className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0" 
                                            type="checkbox" 
                                            id="agree" 
                                            onChange={(e) => { setAgree(e.target.checked) 

                                                if(e.target.checked === true){
                                                    setValidation((prevState) => ({
                                                                                            ...prevState,
                                                                                            agreeWarning: false,
                                                                                        }));
                                                }else{
                                                    setValidation((prevState) => ({
                                                                                            ...prevState,
                                                                                            agreeWarning: true,
                                                                                        }));
                                                }
                                                            
                                            }}
                                        />
                                        <label className="form-check-label" for="agree">
                                            I have read and agree to the website 
                                            <Link to="#" className="text-primary fs-md">terms and conditions </Link>
                                        </label>
                                        
                                    </div>
                                    )}
                                    {/* warning */}
                                        {validation.agreeWarning && (
                                            <div className="error-message mt-2">
                                                <p className="d-flex align-items-center gap-1 text-danger">
                                                    <span className="material-symbols-outlined">
                                                    warning
                                                    </span>
                                                    <span>{t("Please confirm to proceed")}!</span>
                                                </p>
                                            </div>
                                        )}

                                    {DsSignature == ''  && (
                                        <div className="order_btn mt-4">
                                            <button
                                                onClick={saveBillingHandler}
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                                                disabled={isSaving ? true : false}
                                                >
                                                {t("Confirm Billing Details")}
                                                {isSaving && (
                                                    <div
                                                    className="mx-2 spinner-border spinner-border-sm"
                                                    role="status"
                                                    >
                                                    <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    )}
                                    

                                 

                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="cart_rightpnl bg-white shadow-sm rounded-10 p-4">
                                    <h4 className="fs-md fw-semibold text-black mb-3">Order Summary</h4>

                                    <h5 className="text-primary fs-md mb-2 fw-semibold">ORDER ID #{customOrderId} </h5>

                                    <div className="order_table_innr">
                                        <div className="order_info_row d-flex align-items-center justify-content-between py-1">
                                            <h5 className="text-black fs-sm text-black mb-0">Price</h5>
                                            <h5 className="text-black fs-sm text-black mb-0">€ {amount}</h5>
                                        </div>
                                        <div className="order_info_row d-flex align-items-center justify-content-between py-1">
                                            <h5 className="text-black fs-sm text-black mb-0">VAT (0%)</h5>
                                            <h5 className="text-black fs-sm text-black mb-0"> €0</h5>
                                        </div>
                                        <div className="order_info_row d-flex align-items-center justify-content-between py-1">
                                            <h5 className="text-black fs-sm text-black mb-0">Discount (0%)</h5>
                                            <h5 className="text-black fs-sm text-black mb-0">€0</h5>
                                        </div>
                                        <div className="order_info_row total_row d-flex align-items-center justify-content-between py-1">
                                            <h5 className="text-black fs-sm text-black mb-0">Total Payable</h5>
                                            <h2 className="fs-xl fw-semibold text-green mb-0">€{amount}</h2>
                                        </div>

                                        <form name="frm" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" id="payredsys">
                                        
                                            {DsSignatureVersion && DsMarchantParams && DsSignature && (
                                                <>
                                                    <input type="hidden" name="Ds_SignatureVersion" value={DsSignatureVersion} />
                                                    <input type="hidden" name="Ds_MerchantParameters" value={DsMarchantParams} />
                                                    <input type="hidden" name="Ds_Signature" value={DsSignature} />
                                                
                                                    <button className="btn btn-primary submitBtn w-100" type="submit">
                                                        Pay Now
                                                    </button>
                                                </>
                                            )}

                                                                                   
                                        
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </main>
    );

};

export default GuestPayment;
