/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const TicketDashboardFilterPopup = ({
  moduleName = "MOD_TICKETS",
  setStartDate,
  setEndDate,
  setOperationStartDate,
  setOperationEndDate,
  setOrganizations,
  setEnclosures,
  setSectors,
  setEventTypes,
  setEvents,
  setChannels,
  setZones,
  setZipcodes,

  applyFilter,
  setApplyFilter,
}) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [filterDays, setfilterDays] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [filterOperationalDays, setfilterOperationalDays] = useState("");
  const [filterOperationStartDate, setFilterOperationStartDate] = useState("");
  const [filterOperationEndDate, setFilterOperationEndDate] = useState("");

  const [filterChannels, setFilterChannels] = useState([]);
  const [filterZones, setFilterZones] = useState([]);

  const [filterOrganizations, setFilterOrganizations] = useState([]);
  const [filterEnclosers, setFilterEnclosers] = useState([]);
  const [filterSector, setFilterSector] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const [filterEventType, setFilterEventType] = useState([]);
  const [filterZipcodes, setFilterZipcodes] = useState([]);

  const [errorMessage, seterrorMessage] = useState("");

  // filter states
  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);
  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");
  const [filterValidError, setFilterValidError] = useState(false);

  // distinct filter options based on data
  const [companyFilterOptions, setCompanyFilterOptions] = useState([]);
  const [channelFilterOptions, setChannelFilterOptions] = useState([]);
  const [encloserFilterOptions, setEncloserFilterOptions] = useState([]);
  const [eventFilterOptions, setEventFilterOptions] = useState([]);
  const [sectorFilterOptions, setSectorFilterOptions] = useState([]);
  const [zoneFilterOptions, setZoneFilterOptions] = useState([]);

  //function for handling creatable select
  const createSelectOptions = (values) =>
    values.map((value) => ({ value, label: value }));

  const handleSelectChange = (setter) => (selectedOptions) => {
    setter(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    operationDateWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    // if (filterStartDate !== "" && filterEndDate === "") {
    //   setValidation((prevState) => ({ ...prevState, dateWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");

      setApplyFilter(true);

      setStartDate(filterStartDate);
      setEndDate(filterEndDate);
      setOperationStartDate(filterOperationStartDate);
      setOperationEndDate(filterOperationEndDate);

      setOrganizations(filterOrganizations);

      setEnclosures(filterEnclosers);

      setSectors(filterSector);

      setEventTypes(filterEventType);

      setEvents(filterEvents);

      setChannels(filterChannels);

      setZones(filterZones);

      setZipcodes(filterZipcodes)

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasDashboardFilter");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();

    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  // get distinct filter options -----------------------
  const getFilterOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_TICKETS_FILTER_OPTIONS + `?token=${token}`;

      console.log("filter option req url", requestUrl);

      const response = await getData(requestUrl);

      console.log(`filterData data ==========>>>`, response);

      if (response.status) {
        // company
        const companyOptions =
          response.data.company &&
          response.data.company.map((option) => ({
            value: option,
            label: option,
          }));
        setCompanyFilterOptions(companyOptions);

        // channel
        const channelOptions =
          response.data.channel &&
          response.data.channel.map((option) => ({
            value: option,
            label: option,
          }));
        setChannelFilterOptions(channelOptions);

        // encloser
        const encloserOptions =
          response.data.encloser &&
          response.data.encloser.map((option) => ({
            value: option,
            label: option,
          }));
        setEncloserFilterOptions(encloserOptions);

        // event
        const eventOptions =
          response.data.event &&
          response.data.event.map((option) => ({
            value: option,
            label: option,
          }));
        setEventFilterOptions(eventOptions);

        // sector
        const sectorOptions =
          response.data.sector &&
          response.data.sector.map((option) => ({
            value: option,
            label: option,
          }));
        setSectorFilterOptions(sectorOptions);

        // zone
        const zoneOptions =
          response.data.zone &&
          response.data.zone.map((option) => ({
            value: option,
            label: option,
          }));
        setZoneFilterOptions(zoneOptions);
      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      if (validationHandler()) {
        //set filter data for saving
        let filterData = {
          name: filterName,
          parameters: JSON.stringify({
            filterDays,
            filterStartDate,
            filterEndDate,
            filterOperationalDays,
            filterOperationStartDate,
            filterOperationEndDate,
            filterOrganizations,
            filterEnclosers,
            filterSector,
            filterEventType,
            filterEvents,
            filterChannels,
            filterZones,
          }),
          moduleslug: moduleName,
          isdefault: isDefaultFilter,
        };

        console.log(`filterData ==========>>>`, filterData);

        try {
          let requestUrl = url.API_BASE_URL;

          //check condition
          if (filterId) {
            requestUrl =
              requestUrl +
              url.API_UPDATE_FILTER +
              `/${filterId}` +
              `?token=${token}`;
          } else {
            requestUrl =
              requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
          }

          const response = filterId
            ? await putData(requestUrl, filterData)
            : await postData(requestUrl, filterData);

          setFilterIsSaving(false);

          console.log(`filterData response ==========>>>`, response);

          if (response.status) {
            searchHandler();
            if (filterId) {
              getAllFilters(false);
            } else {
              getAllFilters(true);
            }
          } else {
            setFilterSavingErrorMessage(response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        seterrorMessage("Please fill the required fields");
      }
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);

    // reset all previous selected value
    resetHandler();
    // change state to initiate useEffect of ApplyFilter = true
    setApplyFilter(false);

    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);
      const jsonFilterData = JSON.parse(val.parameters);
      // console.log(jsonFilterData);

      if (jsonFilterData.filterDays) {
        setfilterDays(jsonFilterData.filterDays);
      }

      if (
        jsonFilterData.filterStartDate &&
        (!jsonFilterData.filterDays || jsonFilterData.filterDays === "")
      ) {
        setFilterStartDate(jsonFilterData.filterStartDate);
      }

      if (
        jsonFilterData.filterEndDate &&
        (!jsonFilterData.filterDays || jsonFilterData.filterDays === "")
      ) {
        setFilterEndDate(jsonFilterData.filterEndDate);
      }

      if (jsonFilterData.filterOperationalDays) {
        setfilterOperationalDays(jsonFilterData.filterOperationalDays);
      }

      if (
        jsonFilterData.filterOperationStartDate &&
        (!jsonFilterData.filterOperationalDays ||
          jsonFilterData.filterOperationalDays === "")
      ) {
        setFilterOperationStartDate(jsonFilterData.filterOperationStartDate);
      }

      if (
        jsonFilterData.filterOperationEndDate &&
        (!jsonFilterData.filterOperationalDays ||
          jsonFilterData.filterOperationalDays === "")
      ) {
        setFilterOperationEndDate(jsonFilterData.filterOperationEndDate);
      }

      if (jsonFilterData.filterChannels) {
        setFilterChannels(jsonFilterData.filterChannels);
      }

      if (jsonFilterData.filterZones) {
        setFilterZones(jsonFilterData.filterZones);
      }

      if (jsonFilterData.filterOrganizations) {
        setFilterOrganizations(jsonFilterData.filterOrganizations);
      }

      if (jsonFilterData.filterEnclosers) {
        setFilterEnclosers(jsonFilterData.filterEnclosers);
      }

      if (jsonFilterData.filterSector) {
        setFilterSector(jsonFilterData.filterSector);
      }

      if (jsonFilterData.filterEvents) {
        setFilterEvents(jsonFilterData.filterEvents);
      }

      if (jsonFilterData.filterEventType) {
        setFilterEventType(jsonFilterData.filterEventType);
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  // Handle change in Zipcodes ------------------
  const handleZipcodeChange = (newValue) => {
    // Extract the zip code values from the selected options
    const values = (newValue || []).map(option => option.value);
    setFilterZipcodes(values);

    console.log('filter Zip Code values', filterZipcodes);
  };
  const zipcodeOptions = filterZipcodes.map(zipCode => ({ value: zipCode, label: zipCode }));


  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    // console.log(filterId);
    // console.log(filterName);
    if (filterId && filterName !== "") {
      setFilterValidError(false);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;
        const response = await deleteData(requestUrl);
        console.log(`filterData response ==========>>>`, response);
        if (response.status) {
          setFilterList([]);
          setFilterValue(null);
          setFilterId(null);
          setFilterName("");
          resetHandler();
          getAllFilters(false);
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setFilterValidError(true);
    }
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          // setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            //filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            // console.log(jsonFilterData);
            if (jsonFilterData.filterStartDate) {
              setStartDate(jsonFilterData.filterStartDate);
            }

            if (jsonFilterData.filterEndDate) {
              setEndDate(jsonFilterData.filterEndDate);
            }

            if (jsonFilterData.filterOperationStartDate) {
              setOperationStartDate(jsonFilterData.filterOperationStartDate);
            }

            if (jsonFilterData.filterOperationEndDate) {
              setOperationEndDate(jsonFilterData.filterOperationEndDate);
            }

            if (jsonFilterData.filterChannels) {
              setChannels(jsonFilterData.filterChannels);
            }

            if (jsonFilterData.filterZones) {
              setZones(jsonFilterData.filterZones);
            }

            if (jsonFilterData.filterOrganizations) {
              setOrganizations(jsonFilterData.filterOrganizations);
            }

            if (jsonFilterData.filterEnclosers) {
              setEnclosures(jsonFilterData.filterEnclosers);
            }

            if (jsonFilterData.filterSector) {
              setSectors(jsonFilterData.filterSector);
            }

            if (jsonFilterData.filterEvents) {
              setEvents(jsonFilterData.filterEvents);
            }

            if (jsonFilterData.filterEventType) {
              setEventTypes(jsonFilterData.filterEventType);
            }

            setApplyFilter(true);
          }
        } else {
          // setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset search
  const resetHandler = () => {
    // reset component state
    setfilterDays("");
    setFilterStartDate("");
    setFilterEndDate("");
    setfilterOperationalDays("");
    setFilterOperationStartDate("");
    setFilterOperationEndDate("");
    setFilterOrganizations([]);
    setFilterEnclosers([]);
    setFilterSector([]);
    setFilterEventType([]);
    setFilterEvents([]);
    setFilterChannels([]);
    setFilterZones([]);

    // reset props state
    setStartDate(""),
      setEndDate(""),
      setOperationStartDate(""),
      setOperationEndDate(""),
      setOrganizations([]),
      setEnclosures([]),
      setSectors([]),
      setEventTypes([]),
      setEvents([]),
      setChannels([]),
      setZones([]),
      // setApplyFilter(false);
      seterrorMessage("");
  };

  useEffect(() => {
    getFilterOptions();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  useEffect(() => {
    if (filterDays !== "") {
      setFilterEndDate(todayValue);
      // Current date
      const currentDate = new Date(todayValue);

      // Subtract 10 days
      currentDate.setDate(currentDate.getDate() - filterDays);

      // Format as string (YYYY-MM-DD)
      const formattedDate = currentDate.toISOString().slice(0, 10);

      setFilterStartDate(formattedDate); // Output: 2024-06-17
    } else {
      setFilterStartDate("");
      setFilterEndDate("");
    }
  }, [filterDays]);

  useEffect(() => {
    if (filterOperationalDays !== "") {
      setFilterOperationEndDate(todayValue);
      // Current date
      const currentDate = new Date(todayValue);

      // Subtract 10 days
      currentDate.setDate(currentDate.getDate() - filterOperationalDays);

      // Format as string (YYYY-MM-DD)
      const formattedDate = currentDate.toISOString().slice(0, 10);

      setFilterOperationStartDate(formattedDate); // Output: 2024-06-17
    } else {
      setFilterOperationEndDate("");
      setFilterOperationStartDate("");
    }
  }, [filterOperationalDays]);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasDashboardFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* Company or Organization ===========================  */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Organizador</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={companyFilterOptions}
              value={createSelectOptions(filterOrganizations)}
              onChange={handleSelectChange(setFilterOrganizations)}
            />
          </div>

          {/* Enclosure  or Recinto =============================== */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Recinto</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={encloserFilterOptions}
              value={createSelectOptions(filterEnclosers)}
              onChange={handleSelectChange(setFilterEnclosers)}
            />
          </div>

          {/* session date range =============================== */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Fecha sesión</h3>{" "}
            </label>
            <input
              type="number"
              className="form-control fs-sm shadow-none mb-3"
              placeholder="Session Dates"
              min={0}
              value={filterDays}
              onChange={(e) => {
                if (e.target.value > 0) {
                  setfilterDays(e.target.value);
                }
                setValidation((prevState) => ({
                  ...prevState,
                  dateWarning: false,
                }));
                seterrorMessage("");
              }}
            />
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={filterStartDate}
                onChange={(e) => {
                  setFilterStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={filterEndDate}
                onChange={(e) => {
                  setFilterEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>

            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>

          {/* opearation date range ============================= */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Fecha operación</h3>{" "}
            </label>
            <input
              type="number"
              className="form-control fs-sm shadow-none mb-3"
              placeholder="Operation Date"
              min={0}
              value={filterOperationalDays}
              onChange={(e) => {
                if (e.target.value > 0) {
                  setfilterOperationalDays(e.target.value);
                }

                setValidation((prevState) => ({
                  ...prevState,
                  dateWarning: false,
                }));
                seterrorMessage("");
                setFilterOperationEndDate(todayValue);
                console.log(e.target.value);
              }}
            />
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={filterOperationStartDate}
                onChange={(e) => {
                  setFilterOperationStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    operationDateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={filterOperationEndDate}
                onChange={(e) => {
                  setFilterOperationEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    operationDateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>

            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>

          {/* Zipcodes ===========================  */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Zipcodes</h3>{" "}
            </label>
            
            

            <CreatableSelect
              isMulti
              onChange={handleZipcodeChange}
              options={zipcodeOptions}
              placeholder="Add zip codes"
              value={zipcodeOptions.filter(option => filterZipcodes.includes(option.value))}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>

          {/* sector =========================================== */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Sector</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={sectorFilterOptions}
              value={createSelectOptions(filterSector)}
              onChange={handleSelectChange(setFilterSector)}
            />
          </div>

          {/* Event type interest =============================== */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Interés del evento</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              value={createSelectOptions(filterEventType)}
              onChange={handleSelectChange(setFilterEventType)}
            />
          </div>

          {/* Event ==========================================   */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Evento</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={eventFilterOptions}
              value={createSelectOptions(filterEvents)}
              onChange={handleSelectChange(setFilterEvents)}
            />
          </div>

          {/* channel name ============================================ */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Canal</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={channelFilterOptions}
              value={createSelectOptions(filterChannels)}
              onChange={handleSelectChange(setFilterChannels)}
            />
          </div>

          {/* zone name ================================================ */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Zone</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={zoneFilterOptions}
              value={createSelectOptions(filterZones)}
              onChange={handleSelectChange(setFilterZones)}
            />
          </div>
          {/* zone name end ============================================ */}




          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter a title or select a filter!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default TicketDashboardFilterPopup;
