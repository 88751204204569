/* eslint-disable */

/* --------------- importing Images here --------------- */
import defaultUser from "assets/images/default-user.png";
import findelOiLogo from "assets/images/findeloi-logo.png";
import lobeesLogo from "assets/images/lobees-logo-blue.png";
import congratulationsIcon from "assets/images/congratulations-icon.png";
import successCheckPng from "assets/images/success-check-green.png";
import exclamationIcon from "assets/images/exclamation-icon.png";
import blankLogo from "assets/images/blanklogo.jpg";
import scheduleWarning from "assets/images/schedulewarning.png";
import blankSpace from "assets/images/blank-space.png";
import emptyVector from "assets/images/empty-vector.png";
import congratsImage from "assets/images/congrats.png";
import deliveredImg from "assets/images/delivered.png";
import ticketSold from "assets/images/tickets-sold.png";
import averageSales from "assets/images/avarage-sales.png";
import userChartImage from "assets/images/users.png";
import newUsers from "assets/images/new-users.png";
import ticketSoldGraph from "assets/images/tickets-sold-graph.png";
import EN from "assets/images/en.png";
import ES from "assets/images/es.png";
import noAlert from "assets/images/no-alert.png";
import leftBlank from "assets/images/left-blank.png";

/* --------------- importing SVGs here --------------- */
import PdfImg from "assets/images/pdf.svg";
import PdfIcon from "assets/images/pdf-icon.svg";
import DocImg from "assets/images/docs.svg";
import XlsImg from "assets/images/xls.svg";
import VideoImg from "assets/images/video-icon.svg";
import unSubsribeMailSvg from "assets/images/unsubscribe-mail-icon.svg";

export default {
  // ------------------ Exporting Images here ------------------
  defaultUser,
  findelOiLogo,
  congratulationsIcon,
  lobeesLogo,
  successCheckPng,
  exclamationIcon,
  blankLogo,
  scheduleWarning,
  blankSpace,
  emptyVector,
  congratsImage,
  deliveredImg,
  ticketSold,
  averageSales,
  userChartImage,
  newUsers,
  ticketSoldGraph,
  EN,
  ES,
  noAlert,
  leftBlank,

  // ------------------ Exporting SVGs here ------------------
  PdfImg,
  DocImg,
  XlsImg,
  VideoImg,
  PdfIcon,
  unSubsribeMailSvg,
};
