/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const BuyerDistinctEventCount = ({
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,

  applyFilter,
  setApplyFilter,
  refreshData,
  setRefreshData,
}) => {

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [buyersData, setBuyersData] = useState([]);

  const formatNumber = (unformatNum) =>{
    if(unformatNum){
      return unformatNum.toFixed(2);
    }else{
      return unformatNum;
    }
    
  }

  const columns = useMemo(() => [
    { accessorKey: "customer", header: "Buyer"},    
    { accessorKey: "eventCount", header: "Distinct Events Count",
      // Cell: ({ row }) => (
      //   formatNumber(row.original.average_days_difference)
      // )
    }
  ], [],)

  // structure the params received from filter in url params
  const getFilterParamsInUrl = () => {
    let urlParams = "";

    if (applyFilter === false) {
      return urlParams;
    }

    if (startDate !== "" && endDate !== "") {
      urlParams += `&filterstartdate=${startDate}&filterenddate=${endDate}`;
    }

    if (operationStartDate !== "" && operationEndDate !== "") {
      urlParams += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
    }

    if (organizations && organizations.length > 0) {
      organizations.forEach((data) => {
        urlParams += `&filterorganizations[]=${encodeURIComponent(data)}`;
      });
    }

    if (enclosures && enclosures.length > 0) {
      enclosures.forEach((data) => {
        urlParams += `&filterenclosures[]=${encodeURIComponent(data)}`;
      });
    }

    if (sectors && sectors.length > 0) {
      sectors.forEach((data) => {
        urlParams += `&filtersectors[]=${encodeURIComponent(data)}`;
      });
    }

    if (eventTypes && eventTypes.length > 0) {
      eventTypes.forEach((data) => {
        urlParams += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      });
    }

    if (events && events.length > 0) {
      events.forEach((data) => {
        urlParams += `&filterevent[]=${encodeURIComponent(data)}`;
      });
    }

    if (channels && channels.length > 0) {
      channels.forEach((data) => {
        urlParams += `&filterchannel[]=${encodeURIComponent(data)}`;
      });
    }

    if (zones && zones.length > 0) {
      zones.forEach((data) => {
        urlParams += `&filterzone[]=${encodeURIComponent(data)}`;
      });
    }

    console.log("urlParams", urlParams);

    return urlParams;
  };
  //reset all data
  const resetHandler = () => {
    setApplyFilter(false);
    setAvgSales([]);
  };

  const getRecords = async () => {

    try {

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL + 
        url.API_GET_TICKET_DIFF_DATE + 
        `?token=${token}&userrolestring=${userInfo.role.slug}`+
        urlParams;
      
      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log('response',response)

      if (response.status) {
        setBuyersData(response.data);
      }


    } catch (error) {
      console.log(error.message);
    }

  }

  useEffect(() => {
    getRecords();
  }, []);

  // on refresh reload all data
  useEffect(() => {
    if (refreshData) {
      resetHandler();
      getRecords();
      setRefreshData(false);
    }
    
  }, [refreshData]);

  // call all api if filter is applied again
  useEffect(() => {
    console.log("applyFilter triggred >>>", applyFilter);

    if (
      applyFilter &&
      (startDate !== "" ||
        operationStartDate !== "" ||
        channels.length > 0 ||
        organizations.length > 0 ||
        enclosures.length > 0 ||
        sectors.length > 0 ||
        eventTypes.length > 0 ||
        events.length > 0 ||
        channels.length > 0 ||
        zones.length > 0)
    ) {
      // call api to get filtered data
      getRecords();
    }

    // set applyfilter false to reset
    if (applyFilter) {
      setApplyFilter(false);
    }

  }, [applyFilter]);


  return (
    <div className="best-buyer-container py-1">
      <div className="table-responsive">
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={buyersData} // data from api to be displayed
            enableBottomToolbar = {true}
            enableGlobalFilterModes = {true}
            enablePagination= {true}
            enableRowNumbers= {true}
            enableRowVirtualization= {true}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyerDistinctEventCount;
