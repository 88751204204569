/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";
import SubscriptionListHeader from "../SubscriptionListHeader/SubscriptionListHeader";
import { Link } from "react-router-dom";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import SubscriptionListFilterPopup from "../Popup/SubscriptionListFilterPopup";

import ShowPaymentPopup from "../Popup/ShowPaymentsPopup";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";
import ConfirmBulkPayment from "../Popup/ConfirmBulkPayment";
//import AddToListModal from "components/Common/Modal/AddToListModal";

const SubscriptionListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation
  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedSubscriptionIds, setSelectedSubscriptionIds] = useState([]);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");

  // filter data
  const [filterStartDueDate, setfilterStartDueDate] = useState("");
  const [filterEndDueDate, setfilterEndDueDate] = useState("");
  const [filterStartDueTime, setfilterStartDueTime] = useState("");
  const [filterEndDueTime, setfilterEndDueTime] = useState("");

  const [filterSubscriberName, setFilterSubscriberName] = useState("");
  const [filterSubscriberEmail, setFilterSubscriberEmail] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getSubscriptionList = async () => {
    setRowSelection({});
    setSelectedSubscriptionIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_SUBSCRIPTIONS +
        `?token=${token}`;

      if (filterStartDueDate !== "" && filterEndDueDate !== "") {
        requestUrl += `&filterstartduedate=${filterStartDueDate}&filterendduedate=${filterEndDueDate}`;
      }

      // if (filterStartDueTime !== "" && filterEndDueTime !== "") {
      //   requestUrl += `&filterstartduetime=${filterStartDueTime}&filterendduetime=${filterEndDueTime}`;
      // }

      if (filterSubscriberName !== "") {
        requestUrl += `&filtersubscribername=${filterSubscriberName}`;
      }

      if (filterSubscriberEmail !== "") {
        requestUrl += `&filtersubscriberemail=${filterSubscriberEmail}`;
      }

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setSubscriptionList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedCompanyIds) {
      try {
        let statusData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_COMPANY +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllCompanyList();
  };

  // search filter
  useEffect(() => {
    if (
      (filterStartDueDate !== "" && filterEndDueDate !== "") ||
      filterSubscriberName != "" ||
      filterSubscriberEmail != ""
    ) {
      getSubscriptionList();
    }
  }, [
    filterStartDueDate,
    filterEndDueDate,
    filterSubscriberName,
    filterSubscriberEmail,
  ]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSaveList"
            aria-controls="offcanvasSaveList"
            onClick={() => {
              setSelectedSubscriptionId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
        size: 50,
      },
      {
        accessorKey: "customername",
        header: t("Subscriber"),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "35px", height: "35px" }}
              >
                <img
                  src={
                    row.original.customerimage === ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.customerimage
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.customername}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.customeremail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },

      {
        accessorKey: "subscriptiondate",
        header: t("Start Date"),
      },
      {
        accessorKey: "subscriptionenddate",
        header: t("End Date"),
      },
      {
        accessorKey: "plantitle",
        header: t("Plan"),
      },
      {
        accessorKey: "planduration",
        header: t("Duration Month"),
      },
      {
        accessorKey: "subscriptionstatus",
        header: t("status"),
      },
      {
        accessorKey: "customerlastpaymentdate",
        header: t("Last Payment"),
      },
      {
        accessorKey: "customernextpaymentduedate",
        header: t("Next Due Date"),
      },
      {
        accessorKey: "paymentcount",
        header: t("Payments Count"),
      },
      {
        accessorKey: "tags",
        header: t("Label"),
        // Cell: ({ row }) =>
        //   row.original.payments ? row.original.payments.length : 1,
      },
    ],
    [i18n.language]
  );

  // when row selected
  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      console.log("selectedIdsArray", selectedIdsArray);

      setSelectedSubscriptionIds(selectedIdsArray);
    } else {
      setSelectedSubscriptionIds([]);
    }
  }, [rowSelection]);

  // on load of component
  useEffect(() => {
    getSubscriptionList();
    getAllTags();
  }, []);

  const resetFilterData = () => {
    setfilterStartDueDate("");
    setfilterEndDueDate("");
    setFilterSubscriberName("");
    setFilterSubscriberEmail("");
  };

  const refreshRecords = () => {
    resetFilterData();
    getSubscriptionList();
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_SUBSCRIPTION")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <SubscriptionListHeader
              reloadList={refreshRecords}
              changeStatusHandler={changeStatusHandler}
            />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={subscriptionList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedSubscriptionIds}
          moduleName="subscription"
          afterTagModalClose={getSubscriptionList}
        />

        {/* --- add new list --- */}
        <ShowPaymentPopup
          selectedSubscriptionId={selectedSubscriptionId}
          resetSubscriptionId={() => {
            setSelectedSubscriptionId("");
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ------- filter popup ------- */}
        <SubscriptionListFilterPopup
          setfilterStartDueDate={setfilterStartDueDate}
          setfilterEndDueDate={setfilterEndDueDate}
          setfilterStartDueTime={setfilterStartDueTime}
          setfilterEndDueTime={setfilterEndDueTime}
          setFilterSubscriberName={setFilterSubscriberName}
          setFilterSubscriberEmail={setFilterSubscriberEmail}
        />

        {/* ----------- delete warning modal ------------ */}
        <ConfirmBulkPayment
          selectedIds={selectedSubscriptionIds}
          afterModalClose={() => {
            setSelectedSubscriptionIds([]);
            refreshRecords();
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default SubscriptionListBody;
