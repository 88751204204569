import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";


// Generate Invoice
export const generateRecurringInvoice = async(ecosystemSlug, 
                                                invoicePlanId,
                                                invoiceClientEmail,
                                                invoiceProviderEmail
) =>{

    try {

        const data = {
            ecosystemslug : ecosystemSlug,
            planid: invoicePlanId,
            email: invoiceClientEmail,
            invoiceowner: invoiceProviderEmail
        }

        console.log('invoice form data', data);

        let requestUrl = url.API_BASE_URL +
                        url.API_CREATE_GUEST_INVOICE;

        const response = await postData(requestUrl, data);

        console.log('invoice response', response);

        if(response.status){

            return response.data?response.data.invoiceid:null;
        }
            
    } catch (error) {

        console.log('Error', error); 
        return null;

    }
}

// API_REDSYS_RECURRING_PAYMENT
export const processRecurringPayment = async (customOrderId, selectedSubscriptionId) => {

    try {

            const data = {
                invoicecustomid: customOrderId,
                subscriptionid: selectedSubscriptionId
            }

            console.log('recurring form data', data);

            if(customOrderId && selectedSubscriptionId){

                let requestUrl = url.API_BASE_URL +
                            url.API_REDSYS_RECURRING_PAYMENT;

                const response = await postData(requestUrl, data);

                console.log('recurring response', response);

                if(response.status){

                    return response;
                    
                }

            }
            
                
        } catch (error) {

            console.log('Error', error);
            
            return false;

        }

};


const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const redsysPaymentWait = async (count) => {
    console.log('wait and count', count);

    // Ensure the wait time is at least 5 seconds
    if (parseInt(count) < 5) {
        count = 5;
    } else {
        count = parseInt(count);
    }

    // Wait for (1000 * count) milliseconds
    await wait(1000 * count);
};
