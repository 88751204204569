/* eslint-disable */
import Administration from "pages/Administration/Administration";
import Categories from "pages/Administration/Categories/Categories";
import Ecosystem from "pages/Administration/Ecosystem/Ecosystem";
import ModuleList from "pages/Administration/ModuleList/ModuleList";
import ModuleRolePrivilege from "pages/Administration/ModuleRolePrivilege/ModuleRolePrivilege";
import ModuleRoles from "pages/Administration/ModuleRoles/ModuleRoles";
import Roles from "pages/Administration/Roles/Roles";
import Plans from "pages/Administration/Plans/Plans";
import AffiliateProposals from "pages/Affiliations/AffiliateProposals/AffiliateProposals";
import AffiliateRequests from "pages/Affiliations/AffiliateRequests/AffiliateRequests";
import AllLogs from "pages/AllLogs/AllLogs";
import BlogList from "pages/Blog/Manage/BlogList";
import AddUpdateBlog from "pages/Blog/Save/AddUpdateBlog";
import CrmContactDetailsRecievedMail from "pages/CRM/ContactDetailsTimeLine/Mail/CrmContactDetailsRecievedMail";
import CrmContactDetailsSentMail from "pages/CRM/ContactDetailsTimeLine/Mail/CrmContactDetailsSentMail";
import CrmContactDetailsTask from "pages/CRM/ContactDetailsTimeLine/Task/CrmContactDetailsTask";
import CrmContactDetailsTimeLine from "pages/CRM/ContactDetailsTimeLine/TimeLine/CrmContactDetailsTimeLine";
import CrmContactList from "pages/CRM/ContactList/CrmContactList";
import CrmConversationRecievedList from "pages/CRM/Conversation/CrmConversationRecievedList";
import CrmConversationSentList from "pages/CRM/Conversation/CrmConversationSentList";
import CRMLabel from "pages/CRM/Label/CrmLabel";
import CrmLeadAddTask from "pages/CRM/LeadInformation/AddTask/CrmLeadAddTask";
import CrmLeadCommunication from "pages/CRM/LeadInformation/Communication/CrmLeadCommunication";
import CrmLeadInformationDetails from "pages/CRM/LeadInformation/LeadInformationDeatails/CrmLeadInformationDetails";
import CrmLeadList from "pages/CRM/LeadList/Manage/CrmLeadList";
import SaveCrmLead from "pages/CRM/LeadList/Save/SaveCrmLead";
import CrmList from "pages/CRM/List/CrmList";
import CrmMailTemplate from "pages/CRM/MailTemplate/CrmMailTemplate";
import CrmProcess from "pages/CRM/ActivityProcess/CrmProcess";
import CrmSalesOrder from "pages/CRM/SalesOrder/CrmSalesOrder";
import JobInformation from "pages/Challenges/ChallengeDetails/JobInformation/JobInformation";
import HiringProcess from "pages/Challenges/ChallengeDetails/HiringProcess/HiringProcess";
import HiringTeam from "pages/Challenges/ChallengeDetails/HiringTeam/HiringTeam";
import ChallengeResponseList from "pages/Challenges/ChallengeDetails/ChallengeResponses/ChallengeResponseList/ChallengeResponseList";
import JobSurvey from "pages/Challenges/ChallengeDetails/JobSurvey/JobSurvey";
import ChallengeList from "pages/Challenges/ChallengeList/ChallengeList";
import CompaniesLanding from "pages/Companies/CompaniesLanding/CompaniesLanding";
import CompanyFAQ from "pages/Companies/CompanyDetails/CompanyFAQ/CompanyFAQ";
import CompanyGeneralInfo from "pages/Companies/CompanyDetails/CompanyGeneralInfo/CompanyGeneralInfo";
import CompanyTeamMembers from "pages/Companies/CompanyDetails/CompanyTeamMembers/CompanyTeamMembers";
import CouponCreate from "pages/Coupons/CouponCreate/CouponCreate";
import CouponList from "pages/Coupons/CouponList/CouponList";
import CourseCertification from "pages/Courses/CourseDetails/CourseCertification/CourseCertification";
import CourseDetail from "pages/Courses/CourseDetails/CourseDetail/CourseDetail";
import CourseFaq from "pages/Courses/CourseDetails/CourseFaq/CourseFaq";
import CourseLessonsDetailsContent from "pages/Courses/CourseDetails/CourseLessons/CourseLessonsDetails/CourseLessonsDetailsContent/CourseLessonsDetailsContent";
import CourseLessonsDetailsCustomerQueries from "pages/Courses/CourseDetails/CourseLessons/CourseLessonsDetails/CourseLessonsDetailsCustomerQueries/CourseLessonsDetailsCustomerQueries";
import CourseLessonsDetailsFAQ from "pages/Courses/CourseDetails/CourseLessons/CourseLessonsDetails/CourseLessonsDetailsFAQ/CourseLessonsDetailsFAQ";
import CourseLessonsDetailsTask from "pages/Courses/CourseDetails/CourseLessons/CourseLessonsDetails/CourseLessonsDetailsTask/CourseLessonsDetailsTask";
import CourseLessonsList from "pages/Courses/CourseDetails/CourseLessons/CourseLessonsList/CourseLessonsList";
import CourseSubscriber from "pages/Courses/CourseDetails/CourseSubscriber/CourseSubscriber";
import CourseTaskSubmitted from "pages/Courses/CourseDetails/CourseTaskSubmitted/CourseTaskSubmitted";
import CourseTeams from "pages/Courses/CourseDetails/CourseTeams/CourseTeams";
import CourseList from "pages/Courses/CourseList/CourseList";
import Dashboard from "pages/Dashboard/Dashboard";
import EventAgenda from "pages/Events/EventDetails/EventAgenda/EventAgenda";
import EventFeedback from "pages/Events/EventDetails/EventFeedback/EventFeedback";
import EventGallery from "pages/Events/EventDetails/EventGallery/EventGallery";
import EventInfo from "pages/Events/EventDetails/EventInfo/EventInfo";
import EventMembers from "pages/Events/EventDetails/EventMembers/EventMembers";
import EventPartner from "pages/Events/EventDetails/EventPartners/EventPartner";
import SubEvents from "pages/Events/EventDetails/SubEvents/SubEvents";
import EventsList from "pages/Events/EventsList/EventsList";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import GroupCompaniesAdd from "pages/Groups/GroupDetails/GroupCompanies/GroupCompaniesAdd/GroupCompaniesAdd";
import GroupCompaniesList from "pages/Groups/GroupDetails/GroupCompanies/GroupCompaniesList/GroupCompaniesList";
import GroupAddEvent from "pages/Groups/GroupDetails/GroupEvent/GroupAddEvent/GroupAddEvent";
import GroupEventList from "pages/Groups/GroupDetails/GroupEvent/GroupEventList/GroupEventList";
import GroupGallery from "pages/Groups/GroupDetails/GroupGallery/GroupGallery";
import GroupInfo from "pages/Groups/GroupDetails/GroupInfo/GroupInfo";
import GroupMembers from "pages/Groups/GroupDetails/GroupMembers/GroupMembers";
import GroupProductAdd from "pages/Groups/GroupDetails/GroupProduct/GroupProductAdd/GroupProductAdd";
import GroupProductList from "pages/Groups/GroupDetails/GroupProduct/GroupProductList/GroupProductList";
import GroupProductImages from "pages/Groups/GroupDetails/GroupProduct/groupProductDetails/GroupProductImages/GroupProductImages";
import GroupProductOffers from "pages/Groups/GroupDetails/GroupProduct/groupProductDetails/GroupProductOffers/GroupProductOffers";
import GroupProductVariations from "pages/Groups/GroupDetails/GroupProduct/groupProductDetails/GroupProductVariations/GroupProductVariations";
import GroupProductVitalInfo from "pages/Groups/GroupDetails/GroupProduct/groupProductDetails/GroupProductVitalInfo/GroupProductVitalInfo";
import GroupProductWarranty from "pages/Groups/GroupDetails/GroupProduct/groupProductDetails/GroupProductWarranty/GroupProductWarranty";
import GroupList from "pages/Groups/GroupList/GroupList";
import AuthValidate from "pages/Login/AuthValidate";
import Login from "pages/Login/Login";
import Logout from "pages/Login/Logout";
import OfferList from "pages/Offers/OfferList/OfferList";
import OfferResponse from "pages/Offers/OfferResponse/OfferResponse";
import ProcessList from "pages/Processes/ProcessList";
import ProjectDetail from "pages/ProjectManagement/ProjectDetails/ProjectDetail/ProjectDetail";
import ProjectInvestments from "pages/ProjectManagement/ProjectDetails/ProjectInvestments/ProjectInvestments";
import ProjectKPIs from "pages/ProjectManagement/ProjectDetails/ProjectKPIs/ProjectKPIs";
import ProjectLogs from "pages/ProjectManagement/ProjectDetails/ProjectLogs/ProjectLogs";
import ProjectMembers from "pages/ProjectManagement/ProjectDetails/ProjectMembers/ProjectMembers";
import ProjectODS from "pages/ProjectManagement/ProjectDetails/ProjectODS/ProjectODS";
import ProjectReport from "pages/ProjectManagement/ProjectDetails/ProjectReport/ProjectReport";
import ProjectTasks from "pages/ProjectManagement/ProjectDetails/ProjectTasks/ProjectTasks";
import ProjectList from "pages/ProjectManagement/ProjectList/ProjectList";
import ProjectKanbanView from "pages/ProjectManagement/ProjectDetails/ProjectKanbanView/ProjectKanbanView";
import ProjectGanttView from "pages/ProjectManagement/ProjectDetails/ProjectGanttView/ProjectGanttView";

import Register from "pages/Register/Register";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import SuccessStoriesList from "pages/SuccessStories/Manage/SuccessStoriesList";
import AddUpdateSuccessStory from "pages/SuccessStories/Save/AddUpdateSuccessStory";
import SurveyList from "pages/Survey/SurveyList/SurveyList";
import SurveyQuestion from "pages/Survey/SurveyQuestion/SurveyQuestion";
import SurveyResponse from "pages/Survey/SurveyResponse/SurveyResponse";
import VerifyEmail from "pages/VerifiyEmail/VerifyEmail";
import InvoiceList from "pages/Invoice/InvoiceList";
import InvoiceDetails from "pages/Invoice/InvoiceDetails";
import Payment from "pages/Payment/Payment";
import Subscription from "pages/Subscription/Subscription";
import ProductList from "pages/Products/ProductList/ProductList";
import ProductInfo from "pages/Products/ProductDetails/VitalInfo/ProductInfo";
import AppUserList from "pages/AppUser/AppUserList/AppUserList";
import AppUserProfileInfo from "pages/AppUser/AppUserDetails/AppUserProfileInfo/AppUserProfileInfo";
import AppUserCv from "pages/AppUser/AppUserDetails/AppUserCv/AppUserCv";
import AppUserPrivacy from "pages/AppUser/AppUserDetails/AppUserPrivacy/AppUserPrivacy";
import AppUserPassword from "pages/AppUser/AppUserDetails/AppUserPassword/AppUserPassword";
import AppUserAvailibility from "pages/AppUser/AppUserDetails/AppUserAvailibility/AppUserAvailibility";
import ActivityProcessTemplate from "pages/Administration/ActivityProcessTemplate/ActivityProcessTemplate";
import Tickets from "pages/Tickets/Tickets";
import TicketCustomers from "pages/Tickets/Customers";

import ChallengeResponseKanban from "pages/Challenges/ChallengeDetails/ChallengeResponses/ChallengeResponseKanban/ChallengeResponseKanban";
import UnSubscribeToMail from "pages/UnSubscribeToMail/UnSubscribeToMail";
import SubscribeToMail from "pages/SubscribeToMail/SubscribeToMail";
import SaveMailTemplate from "pages/CRM/MailTemplate/SaveMailTemplate";
import ProjectSectionTask from "pages/ProjectManagement/ProjectDetails/ProjectSectionTask/ProjectSectionTask";
import MyTaskList from "pages/MyTask/MyTaskList";
import MyTaskKanban from "pages/MyTask/MyTaskKanban";
import MyTaskGantt from "pages/MyTask/MyTaskGantt";
import AppUserSurveyInvites from "pages/AppUser/AppUserDetails/AppUserSurveyInvites/AppUserSurveyInvites";
import MailTemplateList from "pages/Administration/MailTemplates/MailTemplateList/MailTemplateList";
import SaveMailTemplateDetails from "pages/Administration/MailTemplates/MailTemplateDetails/SaveMailTemplateDetails";
import ActionLogs from "pages/Administration/ActionLogs/ActionLogs";
import GptPrompt from "pages/Administration/GptPormpt/GptPrompt";
import CrmLeadOpportunities from "pages/CRM/LeadInformation/Opportunities/CrmLeadOpportunities";
import Tags from "pages/Administration/Tags/Tags";
import CRMContactOpportunities from "pages/CRM/ContactDetailsTimeLine/Opportunities/CRMContactOpportunities";
import CrmLeadOpportunityKanban from "pages/CRM/LeadInformation/Opportunities/CrmLeadOpportunityKanban";
import CRMContactOpportunityKanban from "pages/CRM/ContactDetailsTimeLine/Opportunities/CRMContactOpportunityKanban";
import CrmOpportunies from "pages/CRM/Opportunities/CrmOpportunies";
import CrmOpportunityKanban from "pages/CRM/Opportunities/CrmOpportunityKanban";
import TaskDetails from "pages/TaskDetails/TaskDetails";
import SaveLeadOpportunities from "../pages/CRM/LeadInformation/Opportunities/SaveLeadOpportunities";
import SaveContactOpportunities from "pages/CRM/ContactDetailsTimeLine/Opportunities/SaveContactOpportunities";
import SaveOpportunities from "pages/CRM/Opportunities/SaveOpportunities";
import EcoLeadList from "pages/CRM/EcoLeads/EcoLeadList";
import SaveProcessTemplate from "pages/Administration/ActivityProcessTemplate/SaveProcessTemplate";
import SaveProcess from "pages/CRM/ActivityProcess/SaveProcess";
import CopyProject from "pages/ProjectManagement/ProjectDetails/CopyProject/CopyProject";
import AppUserSettings from "pages/AppUser/AppUserDetails/AppUserSettings/AppUserSettings";
import UserSurvey from "pages/UserSurvey/UserSurvey";
import AvailabilityCalendar from "pages/AvailabilityCalendar/AvailabilityCalendar";
import CustomerJobFeedback from "pages/CustomerJobFeedback/CustomerJobFeedback";
import CourseChapters from "pages/Courses/CourseDetails/CourseChapters/CourseChapters";

import GuestPayment from "pages/Payment/GuestPayment";
import SubscriptionPayment from 'pages/Payment/SubscriptionPayment';
import SubscriptionCancel from 'pages/Subscription/SubscriptionCancel';
import SuccessPayment from "pages/Payment/SuccessPayment";
import FailurePayment from "pages/Payment/FailurePayment";
import CrmNotes from "pages/CRM/Notes/CrmNotes";
import SurveyInvite from "pages/Survey/SurveyInvite/SurveyInvite";
import SurveyFeedback from "pages/SurveyFeedback/SurveyFeedback";

/*======= public route start =======*/
const publicRoutes = [
  { path: "/", component: Login }, //login page,
  { path: "/auth/validate", component: AuthValidate }, //validate page
  { path: "/admin/register", component: Register }, //register page
  { path: "/admin/forgotpassword", component: ForgotPassword }, //forgot passowrd page
  { path: "/admin/verifyemail", component: VerifyEmail }, //verify email page
  { path: "/admin/resetpassword", component: ResetPassword }, //reset passowrd page
  { path: "/unsubscribetomail/:id", component: UnSubscribeToMail }, //unsubscribe mail page
  { path: "/subscribetomail/:id", component: SubscribeToMail }, //subscribe mail page
  { path: "/admin/customerfeedback/:id", component: CustomerJobFeedback }, //subscribe mail page
  { path: "/auth/payment/guest", component: GuestPayment }, //guest payment 
  { path: '/auth/payment/subscription/:id', component: SubscriptionPayment }, //subscription buy from other platform
  { path: "/auth/payment/success", component: SuccessPayment },
  { path: "/auth/payment/failure", component: FailurePayment },
  { path: '/auth/subscription/cancel/:id', component: SubscriptionCancel },
  //user survey for another component
  {
    path: "/admin/survey/invite/:id",
    component: UserSurvey,
  },

  //guest/user survey
  {
    path: "/admin/survey/feedback/:id",
    component: SurveyFeedback,
  },
];
/*======= public route end =======*/

/*======= protected route start =======*/
const protectedRoutes = [
  /* ========== logout start ======== */
  {
    path: "/logout",
    component: Logout,
  },
  /* ========== logout end ======== */

  { path: "/unsubscribetomail/:id", component: UnSubscribeToMail }, //unsubscribe mail page
  { path: "/subscribetomail/:id", component: SubscribeToMail }, //subscribe mail page
  { path: "/admin/customerfeedback/:id", component: CustomerJobFeedback }, //subscribe mail page
  { path: "/auth/payment/guest", component: GuestPayment }, //guest payment on subscription buy from other platform

  /* ======== subscription start ======== */
  { path: '/auth/payment/subscription/:id', component: SubscriptionPayment }, //subscription buy from other platform
  { path: '/auth/subscription/cancel/:id', component: SubscriptionCancel }, // cancel subscription
  
  /* ======== payment start ======== */
  { path: "/auth/payment/success", component: SuccessPayment },
  { path: "/auth/payment/failure", component: FailurePayment },
  
  /* ======== validation start ======== */
  { path: "/auth/validate", component: AuthValidate }, //validate page
  /* ======== validation end ======== */

  /* ======== dashboard start ========== */
  {
    path: "/admin/dashboard",
    component: Dashboard,
  },
  /* ======== dashboard end ========== */

  /* ======== administration start ========== */
  {
    path: "/admin/administration",
    component: Administration,
  },
  /* ======== administration end ========== */

  /* ======== administration start ========== */
  {
    path: "/admin/administration/ecosystems",
    component: Ecosystem,
  },
  /* ======== administration end ========== */

  /* ======== categories start ========== */
  {
    path: "/admin/administration/categories",
    component: Categories,
  },
  /* ======== categories end ========== */

  /* ======== roles start ========== */
  {
    path: "/admin/administration/roles",
    component: Roles,
  },
  /* ======== roles end ========== */

  /* ======== plans start ========== */
  {
    path: "/admin/administration/plans",
    component: Plans,
  },
  /* ======== plans end ========== */

  /* ======== module start ========== */
  {
    path: "/admin/administration/modules",
    component: ModuleList,
  },
  /* ======== module end ========== */

  /* ======== module roles start ========== */
  {
    path: "/admin/administration/moduleroles",
    component: ModuleRoles,
  },
  /* ======== module roles end ========== */

  /* ======== module role previlege start ========== */
  {
    path: "/admin/administration/moduleroleprivilege",
    component: ModuleRolePrivilege,
  },
  /* ======== module role previlege end ========== */

  /* ======== activity process start ========== */
  {
    path: "/admin/administration/activityprocess/list",
    component: ActivityProcessTemplate,
  },
  {
    path: "/admin/administration/activityprocess/save",
    component: SaveProcessTemplate,
  },
  {
    path: "/admin/administration/activityprocess/save/:id",
    component: SaveProcessTemplate,
  },
  /* ======== activity process end ========== */

  /* ======== mail template start ========== */
  {
    path: "/admin/administration/mailtemplate/list",
    component: MailTemplateList,
  },
  {
    path: "/admin/administration/mailtemplate/save",
    component: SaveMailTemplateDetails,
  },
  {
    path: "/admin/administration/mailtemplate/save/:id",
    component: SaveMailTemplateDetails,
  },
  /* ======== mail template end ========== */
  // action logs
  {
    path: "/admin/administration/actionlogs",
    component: ActionLogs,
  },

  {
    path: "/admin/administration/gptprompt",
    component: GptPrompt,
  },

  {
    path: "/admin/administration/tags",
    component: Tags,
  },

  /* ======== calendar start ========== */
  {
    path: "/admin/admin/availabilitycalendar",
    component: AvailabilityCalendar,
  },
  /* ======== calendar end ========== */

  /* ======== crm start ======= */
  {
    path: "/admin/crm/ecolead/list",
    component: EcoLeadList,
  },
  {
    path: "/admin/crm/lead/list",
    component: CrmLeadList,
  },
  {
    path: "/admin/crm/lead/information/:id",
    component: CrmLeadInformationDetails,
  },
  {
    path: "/admin/crm/lead/addtask/:id",
    component: CrmLeadAddTask,
  },
  {
    path: "/admin/crm/lead/communication/:id",
    component: CrmLeadCommunication,
  },
  {
    path: "/admin/crm/lead/opportunities/:id",
    component: CrmLeadOpportunities,
  },
  {
    path: "/admin/crm/lead/opportunity/kanban/:id",
    component: CrmLeadOpportunityKanban,
  },
  {
    path: "/admin/crm/lead/save",
    component: SaveCrmLead,
  },
  {
    path: "/admin/crm/lead/save/:id",
    component: SaveCrmLead,
  },
  {
    path: "/admin/crm/lead/opportunity/save/:id",
    component: SaveLeadOpportunities,
  },
  {
    path: "/admin/crm/lead/opportunity/save/:id/:invoiceid",
    component: SaveLeadOpportunities,
  },
  {
    path: "/admin/crm/contact/list",
    component: CrmContactList,
  },
  {
    path: "/admin/crm/contact/details/timeline/:id",
    component: CrmContactDetailsTimeLine,
  },
  {
    path: "/admin/crm/contact/details/task/:id",
    component: CrmContactDetailsTask,
  },
  {
    path: "/admin/crm/contact/details/recievedmail/:id",
    component: CrmContactDetailsRecievedMail,
  },
  {
    path: "/admin/crm/contact/details/sentmail/:id",
    component: CrmContactDetailsSentMail,
  },
  {
    path: "/admin/crm/contact/details/opportunities/:id",
    component: CRMContactOpportunities,
  },
  {
    path: "/admin/crm/contact/details/opportunity/kanban/:id",
    component: CRMContactOpportunityKanban,
  },
  {
    path: "/admin/crm/contact/opportunity/save/:id",
    component: SaveContactOpportunities,
  },
  {
    path: "/admin/crm/contact/opportunity/save/:id/:invoiceid",
    component: SaveContactOpportunities,
  },
  {
    path: "/admin/crm/conversation/recieved",
    component: CrmConversationRecievedList,
  },
  {
    path: "/admin/crm/conversation/sent",
    component: CrmConversationSentList,
  },
  {
    path: "/admin/crm/list",
    component: CrmList,
  },
  {
    path: "/admin/crm/mailtemplate/list",
    component: CrmMailTemplate,
  },
  {
    path: "/admin/crm/mailtemplate/save",
    component: SaveMailTemplate,
  },
  {
    path: "/admin/crm/mailtemplate/save/:id",
    component: SaveMailTemplate,
  },
  {
    path: "/admin/crm/label/list",
    component: CRMLabel,
  },
  {
    path: "/admin/crm/process/list",
    component: CrmProcess,
  },
  {
    path: "/admin/crm/process/save",
    component: SaveProcess,
  },
  {
    path: "/admin/crm/process/save/:id",
    component: SaveProcess,
  },
  {
    path: "/admin/crm/salesorder/list",
    component: CrmSalesOrder,
  },
  {
    path: "/admin/crm/opportunity/list",
    component: CrmOpportunies,
  },
  {
    path: "/admin/crm/opportunity/kanban",
    component: CrmOpportunityKanban,
  },
  {
    path: "/admin/crm/opportunity/save/:invoiceid",
    component: SaveOpportunities,
  },
  {
    path: "/admin/crm/note/list",
    component: CrmNotes,
  },
  /* ======== crm end ======= */

  /* ===== events start ======== */
  {
    path: "/admin/events/list",
    component: EventsList,
  },
  {
    path: "/admin/events/info",
    component: EventInfo,
  },
  {
    path: "/admin/events/info/:id",
    component: EventInfo,
  },
  {
    path: "/admin/events/subevents/:id",
    component: SubEvents,
  },
  {
    path: "/admin/events/members/:id",
    component: EventMembers,
  },
  {
    path: "/admin/events/eventpartner/:id",
    component: EventPartner,
  },
  {
    path: "/admin/events/agenda/:id",
    component: EventAgenda,
  },
  {
    path: "/admin/events/gallery/:id",
    component: EventGallery,
  },
  {
    path: "/admin/events/feedback/:id",
    component: EventFeedback,
  },
  /* ===== events end ======== */

  /* ====== blogs start ======== */
  {
    path: "/admin/blogs/list",
    component: BlogList,
  },
  {
    path: "/admin/blog/save",
    component: AddUpdateBlog,
  },
  {
    path: "/admin/blog/save/:id",
    component: AddUpdateBlog,
  },
  /* ====== blogs end ======== */

  /*====== success stories start ======*/
  {
    path: "/admin/successstories/list",
    component: SuccessStoriesList,
  },
  {
    path: "/admin/successstories/save",
    component: AddUpdateSuccessStory,
  },
  {
    path: "/admin/successstories/save/:id",
    component: AddUpdateSuccessStory,
  },
  /*====== success stories end ======*/

  /* ======= survey start ====== */
  {
    path: "/admin/survey/list",
    component: SurveyList,
  },
  //:id means survey id
  {
    path: "/admin/survey/question/list/:id",
    component: SurveyQuestion,
  },
  {
    path: "/admin/survey/invite/list/:id",
    component: SurveyInvite,
  },
  {
    path: "/admin/survey/response/list/:id",
    component: SurveyResponse,
  },

  //user survey for another component
  {
    path: "/admin/survey/invite/:id",
    component: UserSurvey,
  },

  //guest/user survey
  {
    path: "/admin/survey/feedback/:id",
    component: SurveyFeedback,
  },
  /* ======= survey end ====== */

  /* ======= group start ========== */
  {
    path: "/admin/group/list",
    component: GroupList,
  },
  {
    path: "/admin/group/info",
    component: GroupInfo,
  },
  {
    path: "/admin/group/info/:id",
    component: GroupInfo,
  },
  {
    path: "/admin/group/members/:id",
    component: GroupMembers,
  },
  {
    path: "/admin/group/gallery/:id",
    component: GroupGallery,
  },
  {
    path: "/admin/group/product/list/:id",
    component: GroupProductList,
  },

  {
    path: "/admin/group/product/add/:id",
    component: GroupProductAdd,
  },
  {
    path: "/admin/group/product/vital-info/:id",
    component: GroupProductVitalInfo,
  },
  {
    path: "/admin/group/product/variations/:id",
    component: GroupProductVariations,
  },
  {
    path: "/admin/group/product/offers/:id",
    component: GroupProductOffers,
  },
  {
    path: "/admin/group/product/images/:id",
    component: GroupProductImages,
  },
  {
    path: "/admin/group/product/warranty/:id",
    component: GroupProductWarranty,
  },
  {
    path: "/admin/group/companies/:id",
    component: GroupCompaniesList,
  },
  {
    path: "/admin/group/companies/add/:id",
    component: GroupCompaniesAdd,
  },
  {
    path: "/admin/group/events/:id",
    component: GroupEventList,
  },
  {
    path: "/admin/group/events/add/:id",
    component: GroupAddEvent,
  },
  /* ======= group end ========== */

  /* ========= App user start ========== */
  {
    path: "/admin/appuser/list",
    component: AppUserList,
  },

  {
    path: "/admin/appuser/profile",
    component: AppUserProfileInfo,
  },
  {
    path: "/admin/appuser/profile/:id",
    component: AppUserProfileInfo,
  },
  {
    path: "/admin/appuser/cv/:id",
    component: AppUserCv,
  },
  {
    path: "/admin/appuser/surveyinvites/:id",
    component: AppUserSurveyInvites,
  },
  // {
  //   path: "/admin/appuser/courses/:id",
  //   component: ExpertDetailsCourses,
  // },
  // {
  //   path: "/admin/appuser/certification/:id",
  //   component: ExpertDetailsCertification,
  // },
  // {
  //   path: "/admin/appuser/ecoins/:id",
  //   component: ExpertDetailsEcoins,
  // },
  {
    path: "/admin/appuser/privacy/:id",
    component: AppUserPrivacy,
  },
  {
    path: "/admin/appuser/password/:id",
    component: AppUserPassword,
  },
  {
    path: "/admin/appuser/availability/:id",
    component: AppUserAvailibility,
  },
  {
    path: "/admin/appuser/settings/:id",
    component: AppUserSettings,
  },
  /* ========= experts end ========== */

  /* ========= companies start ============ */
  {
    path: "/admin/companies",
    component: CompaniesLanding,
  },
  {
    path: "/admin/companies/info",
    component: CompanyGeneralInfo,
  },
  {
    path: "/admin/companies/info/:id",
    component: CompanyGeneralInfo,
  },
  {
    path: "/admin/companies/teammembers/:id",
    component: CompanyTeamMembers,
  },
  {
    path: "/admin/companies/faq/:id",
    component: CompanyFAQ,
  },
  /* ========= companies end ============ */

  /* ========== project management start ============ */
  {
    path: "/admin/projectmanagement/list",
    component: ProjectList,
  },
  {
    path: "/admin/projectmanagement/details",
    component: ProjectDetail,
  },
  {
    path: "/admin/projectmanagement/details/:id",
    component: ProjectDetail,
  },
  {
    path: "/admin/projectmanagement/copyproject/:id",
    component: CopyProject,
  },
  {
    path: "/admin/projectmanagement/tasksection/:id",
    component: ProjectSectionTask,
  },
  {
    path: "/admin/projectmanagement/tasklist/:id",
    component: ProjectTasks,
  },
  {
    path: "/admin/projectmanagement/kanban/:id",
    component: ProjectKanbanView,
  },
  {
    path: "/admin/projectmanagement/gantt/:id",
    component: ProjectGanttView,
  },
  {
    path: "/admin/projectmanagement/members/:id",
    component: ProjectMembers,
  },
  {
    path: "/admin/projectmanagement/logs/:id",
    component: ProjectLogs,
  },
  {
    path: "/admin/projectmanagement/ods/:id",
    component: ProjectODS,
  },
  {
    path: "/admin/projectmanagement/investment/:id",
    component: ProjectInvestments,
  },
  {
    path: "/admin/projectmanagement/kpis/:id",
    component: ProjectKPIs,
  },
  {
    path: "/admin/projectmanagement/report/:id",
    component: ProjectReport,
  },

  /* ========== project management end ============ */

  /* ========== my task start ============ */
  {
    path: "/admin/mytasklist",
    component: MyTaskList,
  },
  {
    path: "/admin/mytaskkanban",
    component: MyTaskKanban,
  },
  {
    path: "/admin/mytaskgantt",
    component: MyTaskGantt,
  },
  /* ========== my task end ============ */

  /* ========== all log start ============ */
  {
    path: "/admin/alllogs",
    component: AllLogs,
  },
  /* ========== all log end ============ */

  /* ========== courses start ========= */
  {
    path: "/admin/courses",
    component: CourseList,
  },
  {
    path: "/admin/course/save",
    component: CourseDetail,
  },
  {
    path: "/admin/course/save/:id",
    component: CourseDetail,
  },
  {
    path: "/admin/course/chapter/list/:id",
    component: CourseChapters,
  },
  {
    path: "/admin/course/lessons/list/:id",
    component: CourseLessonsList,
  },
  {
    path: "/admin/course/lessons/content/:id",
    component: CourseLessonsDetailsContent,
  },
  {
    path: "/admin/course/lessons/content/:id/:lessonid",
    component: CourseLessonsDetailsContent,
  },
  {
    path: "/admin/course/lessons/task/:id/:lessonid",
    component: CourseLessonsDetailsTask,
  },
  {
    path: "/admin/course/lessons/faq/:id/:lessonid",
    component: CourseLessonsDetailsFAQ,
  },
  {
    path: "/admin/course/lessons/customer-queries/:id/:lessonid",
    component: CourseLessonsDetailsCustomerQueries,
  },

  {
    path: "/admin/course/team/:id",
    component: CourseTeams,
  },
  {
    path: "/admin/course/subscribers/:id",
    component: CourseSubscriber,
  },
  {
    path: "/admin/course/task/list/:id",
    component: CourseTaskSubmitted,
  },
  {
    path: "/admin/course/certification/:id",
    component: CourseCertification,
  },
  {
    path: "/admin/course/faq/:id",
    component: CourseFaq,
  },

  /* ========== courses end ========= */

  /* ============ affliliations start ================ */
  {
    path: "/admin/affiliate-proposals",
    component: AffiliateProposals,
  },
  {
    path: "/admin/affiliate-requests",
    component: AffiliateRequests,
  },
  /* ============ affliliations end ================ */

  /* ============= challenges start =================== */
  {
    path: "/admin/challenges/list",
    component: ChallengeList,
  },
  {
    path: "/admin/challenges/jobinformation",
    component: JobInformation,
  },
  {
    path: "/admin/challenges/jobinformation/:id",
    component: JobInformation,
  },
  {
    path: "/admin/challenges/hiringprocess/:id",
    component: HiringProcess,
  },
  {
    path: "/admin/challenges/hiringteam/:id",
    component: HiringTeam,
  },
  {
    path: "/admin/challenges/responses/list/:id",
    component: ChallengeResponseList,
  },
  {
    path: "/admin/challenges/responses/kanban/:id",
    component: ChallengeResponseKanban,
  },
  {
    path: "/admin/challenges/survey/:id",
    component: JobSurvey,
  },
  /* ============= challenges end =================== */

  /* ============== processes start ====================== */
  { path: "/admin/process", component: ProcessList },
  /* ============== processes end ====================== */

  /* ============ offers start ================ */
  { path: "/admin/offers/list", component: OfferList },
  { path: "/admin/offers/response", component: OfferResponse },
  /* ============ offers start ================ */

  /* =============== coupons start ========================= */
  { path: "/admin/coupons/list", component: CouponList },
  { path: "/admin/coupons/create", component: CouponCreate },
  /* =============== coupons end ========================= */

  /* =============== Invoice start ========================= */
  { path: "/admin/invoice/list", component: InvoiceList },
  {
    path: "/admin/invoice/details",
    component: InvoiceDetails,
  },
  {
    path: "/admin/invoice/details/:id",
    component: InvoiceDetails,
  },
  /* =============== invoice end ========================= */

  /* =============== Payment start ========================= */
  { path: "/admin/payment/list", component: Payment },
  /* =============== payment end ========================= */

  /* =============== Subscriptions start ========================= */
  { path: "/admin/subscription/list", component: Subscription },
  /* =============== subscription end ========================= */

  /* ======= market place start =======*/
  //list
  { path: "/admin/marketplace/list", component: ProductList },

  {
    path: "/admin/marketplace/information",
    component: ProductInfo,
  },
  {
    path: "/admin/marketplace/information/:id",
    component: ProductInfo,
  },
  /* ======= market place end =======*/

  /* =============== task details start ========================= */
  { path: "/admin/task/details/:id", component: TaskDetails },
  /* =============== task details end ========================= */

  /* =============== Tickets start ========================= */
  { path: "/admin/tickets/list", component: Tickets },
  { path: "/admin/ticketscustomer/list", component: TicketCustomers },
  /* =============== Tickets end ========================= */
];
/*======= protected route end =======*/

export { publicRoutes, protectedRoutes };
